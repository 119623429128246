@import url(../variable.less);

header {
  padding: 16px 0;
  position: sticky;
  top: 0;
  z-index: 100;
  background: var(--tq-neutral-1000);
  @media @screen1024 {
    padding: 13px 0;
  }
  &.header-small {
    height: 45px;
    padding: 0;
    display: flex;
    align-items: center;
    .container {
      width: 100%;
    }
    .header-wrapper .header-left {
      .logo-wrapper {
        height: 32px;
        display: block;
        @media @screen375 {
          height: 24px;
        }
      }
      .menu-icon {
        height: 32px;
        font-size: 20px;
        @media @screen767 {
          font-size: 0;
        }
        svg {
          width: 18px;
          height: auto;
          vertical-align: middle;
          display: block;
          margin-top: -2px;
          @media @screen767 {
            margin: 0;
          }
        }
      }
    }
    .header-profile-popover {
      @media @screen767 {
        top: 45px !important; /*overwrite the popover inline css*/
      }
    }
  }
  &.header-medium {
    .header-profile-popover {
      @media @screen767 {
        top: 66px !important; /*overwrite the popover inline css*/
      }
    }
  }
  &.header-large {
    .header-profile-popover {
      @media @screen767 {
        top: 66px !important; /*overwrite the popover inline css*/
      }
    }
  }

  // .responsive-menu-header{

  //   @media @screen1024 {
  //     padding: 12px 24px;
  //   }
  // }
  .header-wrapper {
    .d-flex;
    .align-center;
    justify-content: space-between;

    .header-left {
      .d-flex;
      flex: 1;
      .align-center;

      .menu-icon {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 12px 5px 12px;
        border: 1px solid var(--tq-neutral-700);
        color: var(--tq-white-90);
        font-size: 25px;
        line-height: 1;
        font-family: var(--tq-secondary-font);
        @media @screen1024 {
          font-size: 18px;
          padding: 6px 8px 3px 8px;
        }
        @media @screen767 {
          font-size: 0;
          gap: 0;
          padding: 6px;
        }
        svg {
          @media @screen767 {
            width: 18px;
            height: auto;
          }
        }
      }
      & > div.logo-wrapper {
        display: none;
        @media @screen1024 {
          display: inline-block;
          margin-left: 12px;
        }
      }
      .logo-wrapper {
        display: inline-block;
        position: relative;
        height: 45px;
        &.medium {
          height: 40px;
        }
        &.large {
          height: 45px;
        }
        img {
          height: 100%;
          width: auto;
          image-rendering: -webkit-optimize-contrast;
        }

        @media @screen1024 {
          height: 36px;
          &.medium {
            height: 34px;
          }
          &.large {
            height: 40px;
          }
        }
        @media @screen375 {
          height: 30px;
          &.medium {
            height: 32px;
          }
          &.large {
            height: 38px;
          }
        }
      }

      .menu-bar {
        flex: 1;
        .d-flex;
        margin-left: 24px;
        @media @screen1024 {
          margin-left: 16px;
        }
        @media @screen767 {
          margin-left: 8px;
        }
        &.mx-auto {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          justify-content: center;
          margin-left: 0;
          @media @screen1024 {
            margin-left: 0;
          }
          @media @screen767 {
            margin-left: 0;
          }
          @media @screen375 {
            position: relative;
          }
        }
        &.ml-auto {
          justify-content: flex-end;
          margin-left: 24;
          @media @screen1024 {
            margin-left: 16;
          }
          @media @screen767 {
            margin-left: 8;
          }
          @media @screen375 {
            position: relative;
          }
        }
        &.menu-open {
          left: 0;
          .ant-menu {
            left: 0;
          }
        }

        .responsive-close-block {
          position: absolute;
          top: 16px;
          right: 16px;
          color: @tq-neutral-100;
          text-align: right;
        }

        .r-logo {
          padding: 20px 16px 20px 24px;
          border-bottom: 1px solid rgba(245, 238, 228, 0.15);

          svg {
            display: block;
            height: 23px;
            width: auto;
          }
        }

        .header-btn {
          max-width: 152px;
          width: 100%;
          .mr-24;
          text-align: right;

          @media @screen1024 {
            padding: 24px 24px 40px 24px;
            max-width: 100%;
            margin: 0;
            display: none;
          }

          @media @screen767 {
            display: block;
          }

          button {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 100%;
            max-width: 82px;
            height: 42px;
            padding: 9px 7px;
            border-radius: 8px;
            font-family: var(--tq-primary-font);

            @media @screen1024 {
              background: @tq-neutral-100;
              color: @tq-neutral-800;
              height: 45px;
              max-width: 100%;
              .label-l;
            }
          }
        }
      }

      .ant-menu {
        border: 0;
        display: flex;
        background: transparent;
        flex-direction: column;

        .ant-menu-submenu {
          .d-flex;
          .align-center;

          .ant-menu-submenu-title {
            .d-flex;
            .align-center;
          }

          .ant-menu-title-content {
            font: @tq-label-s;
            letter-spacing: 0.09em;
            &:hover {
              color: @tq-red;
            }
          }
        }

        .ant-menu-item {
          .d-flex;
          .align-center;
          line-height: 20px;
          padding: 0;
          margin: 0;
          padding-bottom: 12px;
          width: 100%;
          overflow: unset;
          color: @tq-neutral-100;
          background: transparent !important;
          &:last-child {
            padding-bottom: 0;
          }

          &:hover {
            color: @tq-red;
          }
          &:active {
            background: transparent;
          }

          .ant-menu-title-content {
            font: @tq-label-s;
            letter-spacing: 0.09em;
            @media @screen767 {
              font: @tq-label-l;
              letter-spacing: 0.09em;
            }

            a {
              color: var(--tq-white-90);
              .relative;
              display: flex;
              gap: 8px;
              align-items: center;
              font-family: var(--tq-secondary-font);
              &:hover {
                color: var(--tq-neutral-900);
              }
            }
          }
        }
      }
    }

    .header-right {
      .d-flex;
      .align-center;
      z-index: 1;
      .ant-avatar {
        background: transparent;
        .text-white-90;
      }
      a {
        color: var(--tq-white-90);
      }
      .ant-avatar {
        .ant-avatar-string {
          line-height: 35px;
          svg {
            vertical-align: middle;
          }
        }
      }
      & > a {
        span {
          display: inline-block;
          vertical-align: middle;
        }

        svg {
          @media @screen767 {
            width: 24px;
            height: 24px;
          }
        }
      }

      .header-btn {
        max-width: 152px;
        width: 100%;
        .mr-24;
        text-align: right;

        button {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 100%;
          max-width: 82px;
          height: 42px;
          padding: 9px 7px;
          border-radius: 8px;
          font-family: var(--tq-primary-font);

          @media @screen1024 {
            .button-s;
            padding: 10px 12px 12px;
            height: 38px;
          }
        }
      }

      .ant-avatar {
        cursor: pointer;
        flex: 0 0 40px;
        .ant-avatar-string {
          color: @tq-white-90;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .auth-link {
        margin-left: 24px;
        @media @screen767 {
          margin-left: 16px;
        }
        ul {
          list-style: none;
          gap: 16px;
          align-items: center;
          @media @screen767 {
            gap: 8px;
            flex-direction: row-reverse;
          }
          @media @screen540 {
            gap: 4px;
          }

          li {
            .link-auth {
              border: 1px solid var(--tq-white-90);
              padding: 8px 12px;
              height: 32px;
              font-family: var(--tq-secondary-font);
              font-size: 17px;
              line-height: 1;
              display: inline-block;
              cursor: pointer;
              &.search-link {
                border: 1px solid var(--tq-neutral-700);
                padding: 3px 4px;
                @media @screen767 {
                  padding: 0;
                  border: 0;
                  height: auto;
                }
                svg {
                  vertical-align: middle;
                }
              }
            }
            .auth-link {
              svg {
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
  }
}

.ant-menu-submenu {
  .ant-menu {
    .ant-menu-title-content {
      a {
        .label-s;
        color: @tq-neutral-900;
        line-height: 40px;
        gap: 8px;
      }
    }
  }
}
.ant-popover {
  &.header-menu-popover {
    .ant-popover-inner {
      background: var(--tq-neutral-700);
      .ant-menu-light {
        background: transparent;
      }
      .ant-menu-item {
        height: auto;

        .ant-menu-title-content {
          text-align: left;
        }
        a {
          font: var(--tq-text-small);
          color: var(--tq-black-90);
          position: relative;
        }
      }
    }
  }
}
header {
  &.header-small {
    & + .wrapper {
      min-height: calc(100vh - 119px);
      .subscription-header {
        top: 45px;
      }
    }
  }
  &.header-medium {
    & + .wrapper {
      min-height: calc(100vh - 147px);
      .subscription-header {
        top: 66px;
      }
    }
  }
  &.header-large {
    & + .wrapper {
      min-height: calc(100vh - 147px);
      .subscription-header {
        top: 66px;
      }
    }
  }
}
