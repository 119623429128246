.my-playlist {
  .container {
    @media @screen767 {
      padding: 0;
    }
  }

  .section-header {
    @media @screen767 {
      padding: 0 16px;
    }

    .sh-top {
      .playlist-btn {
        @media @screen767 {
          padding: 8px 12px 10px 12px;
        }

        svg {
          height: 24px;
          width: 24px;

          @media @screen1024 {
            height: 18px;
            width: 18px;
          }

          @media @screen767 {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }
}

.playlist-card {
  width: 100%;
  display: block;

  .playlist-grid {
    width: 100%;
  }

  .playlist-img {
    width: 100%;
    margin-right: 0;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    padding-top: 56.173%;
    margin-bottom: 12px;

    @media @screen1024 {
      margin-bottom: 8px;
    }

    .playlist-overlay {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 104px;
      background: rgba(0, 0, 0, 0.6);
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 6px;

      .playlist-count {
        .label-m;
        .text-n-100;
      }
    }
  }

  .playlist-title {
    .text-md-bold;
    .text-n-900;
    display: block;
    word-wrap: break-word;
  }
}

.responsive-playlist {
  display: block;
  width: 100%;

  ul {
    li {
      a {
        display: flex;
        padding: 16px;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid @tq-border-bottom;
        .text-n-900;

        .list-left {
          display: flex;
          align-items: center;
          word-break: break-word;
          padding-right: 10px;
          .gap-6;
          .text-md;
          & svg {
            min-width: 20px;
          }
        }

        .list-right {
          .gap-8;
          display: flex;
          align-items: center;

          .count {
            padding: 8px 12px;
            .bg-n-400;
            display: inline-block;
            .text-small;
            border-radius: 8px;
          }
        }
      }

      &:last-child {
        a {
          border-bottom: 1px solid @tq-border-bottom;
        }
      }
    }
  }
}

.playlist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid @tq-border-bottom;

  @media @screen767 {
    padding: 0 16px;
    padding-bottom: 12px;
  }

  .playlist-header-right {
    @media @screen767 {
      .gap-8;
    }

    .cs-button.ant-btn.ant-btn-sm {
      @media @screen767 {
        padding: 0 12px;
      }

      svg {
        @media @screen767 {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  .playlist-header-left {
    h3 {
      word-break: break-word;
      padding-right: 10px;
      margin-bottom: 4px;
      .heading-h3;

      @media @screen767 {
        .heading-h5;
      }
    }

    p {
      .text-md;
      .text-n-800;
    }
  }
}

.playlist-popup-form {
  margin: -12px;
  margin-top: 0;
}

.playlist-d-btn {
  .text-small;

  @media @screen767 {
    .button-m;
  }
}

.new-playlist-wrapper {
  .video-list button {
    height: 100%;
  }

  .video-card.video-list .video-image {
    width: 24.108%;
    padding-top: 13.542%;

    @media @screen1024 {
      width: 27.482%;
      padding-top: 15.523%;
    }

    @media @screen767 {
      width: 35%;
      padding-top: 20%;
    }
  }

  .video-card.video-list .vc-description {
    @media @screen1024 {
      padding-right: 50px;
      padding-left: 16px;
    }

    @media @screen767 {
      padding-right: 8px;
      padding-left: 12px;
    }
  }
}

.playlist-modal {
  .ant-checkbox-wrapper {
    color: var(--tq-black-90);
    .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
      background: @tq-neutral-1000;
    }
    .ant-checkbox .ant-checkbox-inner {
      background: transparent;
      border-radius: 5px;
    }
  }
}
