.heroblock {
  padding: 196px 0 215px 0;

  @media @screen1024 {
    padding: 327px 0;
  }

  @media @screen767 {
    padding: 131px 0 101px 0;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background: linear-gradient(
        180deg,
        transparent 36.75%,
        transparent 36.76%,
        rgb(0 0 0 / 0%) 66.73%,
        rgba(0, 0, 0, 0.85)
      ),
      linear-gradient(0deg, rgb(0 0 0 / 34%), rgb(0 0 0 / 34%));
  }

  video {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .herocontent {
    max-width: 895px;
    width: 100%;
    margin: auto;
    position: relative;
    z-index: 2;

    @media @screen1024 {
      max-width: 600px;
    }

    .title {
      .display-h0;
      overflow: hidden;
      white-space: pre-wrap;

      @media @screen1024 {
        .display-h1;
      }
    }

    .description {
      .paragraph-l-xl;
      overflow: hidden;
      white-space: pre-wrap;

      @media @screen1024 {
        .text-md;
      }

      @media @screen767 {
        .paragraph-l;
      }
    }

    .date-heading {
      .heading-h4;

      @media @screen767 {
        .heading-h5;
        .mb-16;
      }
    }

    .hero-button-wrapper {
      .d-flex;
      .align-center;
      gap: 32px;
      .mt-48;

      @media @screen767 {
        gap: 12px;
        .mt-32;
        flex-wrap: wrap;
      }

      button {
        @media @screen767 {
          flex: 1;
        }
      }
    }
  }
}
