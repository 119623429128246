.faq-section {
  padding: 100px 0 80px 0;
  @media @screen767 {
    padding: 60px 0 40px 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 16px;
  }

  .faq-section-title {
    .mb-32;

    @media @screen767 {
      .mb-16;
    }

    .title {
      .display-h2;
      .mb-8;

      @media @screen767 {
        .display-h4;
      }
    }

    .subtitle {
      .paragraph-l-xl;

      @media @screen767 {
        .paragraph-l;
      }
    }
  }

  .ant-collapse {
    .d-flex;
    .align-start;
    flex-wrap: wrap;

    .ant-collapse-item {
      width: 100%;
      margin-bottom: 20px;
      display: inline-block;

      @media @screen767 {
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }
}
