.explore-thinq {
  padding: 100px 0;

  h2 {
    .display-h2;
    .mb-40;
  }

  &.marketing-section {
    padding: 120px 0 60px 0;

    .ctacard.ctaMarketing {
      margin-bottom: 60px;
    }
  }
}

.in-the-media {
  padding: 100px 0;
  .bg-n-900;

  @media @screen767 {
    padding: 60px 0;
  }

  h2 {
    .display-h2;
    .text-n-100;
    .text-center;
    margin-bottom: 48px;

    @media @screen767 {
      margin-bottom: 32px;
      .display-h3;
    }
  }

  .company-logos {
    max-width: 990px;
    width: 100%;
    margin: auto;

    ul {
      list-style: none;
      .d-flex;
      gap: 80px;

      @media @screen767 {
        flex-wrap: wrap;
        gap: 12px 40px;
        .justify-center;
      }

      li {
        position: relative;
        height: 75px;
        flex: 1;

        @media @screen767 {
          width: calc(33.33% - 27px);
          flex: 0 0 calc(33.33% - 27px);
        }
      }
    }
  }
}