.leader-board-section {
  padding-top: 80px;
  position: relative;
  min-height: calc(100vh - 119px);
  .cover-image {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .container {
    position: relative;
  }
  .leader-board-wrapper {
    max-width: 620px;
    margin: auto;
    width: 100%;
    position: relative;
    min-height: calc(100vh - 257px);
    .leader-board-rank {
      max-width: 274px;
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      height: 66px;
      top: -30px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .rank-badge {
        height: 70px;
        width: 70px;
        position: relative;
        &.rank-first {
          height: 84px;
          width: 84px;
          .rank-name {
            font-size: 42px;
            line-height: 1;
          }
        }
        .rank-name {
          color: #fff;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center;
          top: 45%;
          transform: translateY(-50%);
          font-size: 34px;
          line-height: 1;
          font-family: var(--tq-secondary-font);
          text-transform: uppercase;
        }
      }
    }
    .leader-board-list {
      width: 100%;
      display: block;
      padding: 66px 12px 9px 12px;
      @media @screen767 {
        padding: 66px 8px 9px 8px;
      }
      background: #272727e5;
      box-shadow: 0px 4px 48.1px 0px #ffffff1f inset;
      border-radius: 16px 16px 0 0;
      ul {
        counter-reset: counter;
        list-style: none;
        list-style: none;
        max-height: calc(100vh - 332px);
        overflow: auto;
        padding: 0 20px;
        @media @screen767 {
          padding: 0 8px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: transparent;
        }

        &::-webkit-scrollbar {
          width: 5px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background-color: #979797;
        }
        li {
          counter-increment: counter;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px;
          color: #fff;
          font-family: var(--tq-secondary-font);
          font-size: 18px;
          line-height: 27px;
          text-transform: uppercase;
          position: relative;
          padding-left: 44px;

          &.active {
            background: @tq-neutral-900;
            box-shadow: 0px 0px 13.4px 0px #ff550080,
              0px 0px 6.3px 0px #ffffff80 inset;
          }
          div {
            font-family: var(--tq-secondary-font);
            span {
              text-transform: lowercase;
            }
          }
          &:not(:last-child) {
            border-bottom: 1px solid #ffffff33;
          }
          &::before {
            content: counter(counter) '';
            position: absolute;
            left: 16px;
          }
        }
      }
    }
  }
  .subscription-header {
    background: #efebe9;
    .sh-header-wrapper {
      @media @screen540 {
        flex-direction: column;
      }
      h2 {
        font: var(--tq-display-h2);
        margin: 0;
      }
      .ant-btn {
        @media @screen540 {
          width: 100%;
        }
      }
    }
  }
}
