.plan-price-section.ticket-share-section {
  padding: 76px 0;
  @media @screen767 {
    padding: 46px 0;
  }
}
.ticket-wrapper {
  max-width: 304px;
  width: 100%;
  color: #fff;
  text-align: center;
  margin: auto;
  position: relative;
  margin-bottom: 36px;
  img {
    width: 100%;
    height: auto;
  }
}
.share-section {
  padding: 24px 0;
  background: #ebebeb;
  border-radius: 12px;
  max-width: 470px;
  margin: auto;
  .share-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    padding: 0 24px;
  }
  .share-list {
    padding: 0 24px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    ul {
      padding: 0;
      list-style: none;
      display: flex;
      gap: 9px;
      li {
        text-align: center;
        min-width: 68px;
        padding: 5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
      }
    }
  }
}
