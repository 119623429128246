.account-page-wrapper {
  display: flex;
  position: relative;
  .account-profile {
    height: 56px;
    width: 100%;
    background: #000000;
    position: absolute;
    top: 0;
  }
  &.account-setting-page {
    .sidebar-wrapper {
      top: 56px;
      height: calc(100% - 56px);
    }
    .avatar-profile {
      width: 100%;
      margin: auto;
      text-align: center;
      margin-bottom: 20px;
      .ant-avatar {
        background: var(--tq-neutral-900);
        margin-top: 8px;
        line-height: 64px;
        border: 2px solid #000;
        position: relative;
        @media @screen767 {
          height: 64px;
          width: 64px;
        }
        &::before {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          inset: 0;
          border: 2px solid #fff;
          border-radius: 50%;
        }
      }
      .user-badge-image {
        padding: 10px 24px;
        font-size: 20px;
        line-height: 24px;
        font-family: var(--tq-secondary-font);
        border-block: 1px solid #000;
        margin-top: 12px;
        display: inline-block;
      }
      .profile-date {
        font-family: var(--tq-secondary-font);
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-align: center;
        margin-top: 8px;
      }
    }
  }
  .sidebar-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 180px;
    width: 100%;
    border-right: 1px solid #bfbfbf;
    @media @screen767 {
      display: none;
    }

    ul {
      li {
        border-top: 1px solid #bfbfbf;
        &.active {
          background: #ededed;
        }
        &:last-child {
          border-bottom: 1px solid #bfbfbf;
        }
        a {
          .text-small;
          .d-flex;
          .align-center;
          padding: 10px 16px;
          color: #111111;
          font-size: 20px;
          font-family: var(--tq-secondary-font);
          @media @screen767 {
            padding: 16px;
            font-family: var(--tq-secondary-font);
          }
          svg {
            display: none;
          }
        }
      }
    }
    .profile-btn {
      .ant-btn {
        background: transparent;
        color: #111111;
        font-size: 20px;
        padding: 0;
        font-family: var(--tq-secondary-font);
        height: auto;
        padding: 10px 16px;
        &:hover {
          background: transparent;
          color: #111111;
        }
        span {
          font-family: var(--tq-secondary-font);
        }
        svg {
          display: none;
        }
      }
    }
  }
  .account-all-page-section {
    flex: 1;
    width: 100%;
    overflow: auto;
    @media @screen1150 {
      margin-left: 180px;
    }
    @media @screen767 {
      margin-left: 0;
    }
    .container {
      width: 100%;
    }
  }
}
header {
  &.header-small {
    & + .wrapper {
      .account-all-page-section {
        min-height: calc(100vh - 119px);
      }
    }
  }
  &.header-medium {
    & + .wrapper {
      .account-all-page-section {
        min-height: calc(100vh - 147px);
      }
    }
  }
  &.header-large {
    & + .wrapper {
      .account-all-page-section {
        min-height: calc(100vh - 147px);
      }
    }
  }
}
