.manage-plan-v2 {
  .mp-v2-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -10px;
    align-items: flex-start;
    .mp-v2-block {
      position: relative;
      min-width: 310px;
      max-width: 310px;
      width: calc(20% - 20px);
      margin: 10px;
      background: #fff;
      border-radius: 8px;
      overflow: hidden;

      .best-value-block {
        position: absolute;
        top: 26px;
        right: -4px;
      }

      @media @screen1450 {
        width: calc(33.33% - 20px);
      }
      .mp-v2-header {
        padding: 24px 0;
        background: var(--tq-neutral-900);
        text-align: center;
        h3 {
          text-align: center;
          line-height: 1;
          padding: 9px 24px 5px 24px;
          display: inline-block;
          border-block: 1px solid #ffffffc7;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
        }
      }
      .mp-v2-body {
        padding: 25px 16px 16px 16px;
        ul {
          list-style: none;
          margin-bottom: 16px;
          li {
            margin-bottom: 4px;
            font-size: 15px;
            line-height: 22px;
            position: relative;
            padding-left: 28px;
            svg {
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
        .primary-button {
          span {
            display: inline-block;
          }
          .ant-btn {
            font-weight: 600;
            &:disabled {
              border: 0;
              background: #8a7c78;
              color: #fff;
            }
          }
        }
        .subscribe-button {
          font-size: 16px;
          line-height: 24px;

          .ant-btn {
            font-weight: 600;
            margin-top: 8px;
            border: 2px solid #ffb700;
            background: transparent;
            color: #000;
            &:disabled {
              border: 0;
              background: #8a7c78;
              color: #fff;
            }
          }
        }
        .cancel-text {
          .text-tiny;
        }
        .mp-v2-cancel-subscription {
          text-align: center;
          margin: 10px 0;
          .ant-btn {
            color: #000;
            text-decoration: underline;
            height: auto;
            padding: 0;
            font-weight: 500;
            font-size: 14px;
          }
        }
        .mp-v2-custom-amount-text {
          font-size: 16px;
          color: #1a1a19;
          line-height: 21px;
          font-weight: 700;
          margin-bottom: 8px;
        }
        .mp-v2-amount-text {
          font-size: 14px;
          color: #1a1a19;
        }
        .cancelled-text {
          color: @tq-red-2;
          font-size: 14px;
          font-weight: 700;
          font-style: italic;
          text-align: center;
        }
        .gray-active-button {
          background-color: #8a7c78;
        }
      }
    }
  }
}
