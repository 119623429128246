.subscription-header {
  position: sticky;
  left: 0;
  width: 100%;
  padding: 8px 0;
  z-index: 4;
  background: var(--tq-white-70);
  transition: ease 0.25s;
  text-align: center;
  .sh-header-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    @media @screen767 {
      gap: 8px;
    }
  }
  h5.ant-typography {
    margin: 0;
    .display-h5;
    text-transform: uppercase;
    @media @screen540 {
      .display-h6;
    }
  }
  button.ant-btn {
    padding: 0 20px;
    span {
      font-family: var(--tq-secondary-font);
      font-size: 18px;
      line-height: 42px;
    }
  }
}
.subscription-block-wrapper {
  &.modal-subscription {
    position: fixed;
    inset: 0;
    z-index: 999999;
    &::before {
      content: '';
      position: fixed;
      inset: 0;
      background: #00000099;
      backdrop-filter: blur(24px);
    }
    .container {
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .sb-cancel {
    position: absolute;
    top: 50px;
    right: 30px;
    color: var(--tq-black-90);
  }
  .container {
    max-width: 600px;
    padding: 0px;
  }
  .subscription-block {
    background: var(--tq-white-700);
    border: 1px solid var(--tq-white-700);
    color: #000000;
    border-radius: 8px;
    display: flex;
    gap: 24px;
    overflow: hidden;
    min-width: 540px;
    @media @screen767 {
      min-width: 240px;
    }
    @media @screen540 {
      min-width: 240px;
    }
    .sb-block {
      min-width: 168px;
      width: 30%;
      padding-top: 104px;
      border-radius: var(--ant-border-radius-lg);
      overflow: hidden;
      &::before {
        content: '';
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(
          270deg,
          #000000 0.52%,
          rgba(0, 0, 0, 0) 50%
        );
        z-index: 1;
      }
      @media @screen767 {
        position: absolute;
        width: 168px;
        top: 0;
        left: 0;
        height: 100%;
      }
      img {
        object-fit: cover;
      }
    }
    .sb-content {
      padding: 40px;
      text-align: center;
      flex: 1 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      background-color: var(--tq-white-70);
      color: var(--tq-white-700);
      &-description {
        text-transform: uppercase;
      }
      @media @screen767 {
        z-index: 1;
        width: calc(100% - 100px);
        padding: 24px;
      }
      .ant-typography-secondary {
        color: var(--tq-black-90);
      }
      h5 {
        .display-h1;
        font-weight: 700;
        letter-spacing: 0.01em;
        margin: 0;
      }
      h4 {
        margin: 4px 0;
        @media @screen767 {
          font-size: 18px;
        }
      }
      button {
        padding: 0 20px;
        span {
          font-family: var(--tq-secondary-font);
          font-size: 18px;
        }
      }
      .cta-btn {
        width: 100%;
        margin: 12px 0px;
        height: 64px;
        span {
          font-size: 20px;
        }
      }
      .plan-name {
        background-color: #525252;
        padding: 16px 32px;
        border-radius: 4px;
        color: var(--tq-white-70);
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
        @media @screen767 {
          font-size: 16px;
          padding: 8px 12px;
        }
      }
      .plan-price {
        text-align: start;
        font-size: 18px;
      }
      .sb-footer {
        color: #000;
        font-size: 20px;
        a {
          color: #000;
          text-decoration: underline;
          font-weight: 700;
        }
      }
    }
  }
}
