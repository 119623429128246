.collection-items-section {
  .collection-item-container {
    max-width: 640px;
    margin: auto;
    .cover-img {
      margin-bottom: 32px;
      position: relative;
      padding-top: 64.688%;
      @media @screen767 {
        margin-bottom: 16px;
      }
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .collection-items-details {
      @media @screen767 {
        padding: 0 16px;
      }
    }
    .collection-item-list-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media @screen767 {
        margin-top: 14px;
      }
    }
    .collection-item-list {
      background: @tq-neutral-700;
      padding: 12px 16px;
      border-radius: 8px;
      &.active {
        background: transparent;
        margin-bottom: 32px;
        .title {
          color: var(--tq-neutral-900);
        }
        .arrow-progress {
          background: var(--tq-neutral-900);
          .progress-bar {
            display: none;
          }
        }
      }
      .episode-description-wrapper {
        margin-top: 2px;
        display: flex;
        align-items: center;
        gap: 16px;
        .episode-description {
          width: calc(100% - 64px);
          p {
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
          }
        }
      }
    }
  }
}
.arrow-progress {
  position: relative;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background: var(--tq-neutral-600);
  .progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transform: rotate(-90deg);
    @progress-bar-stroke-width: 2;
    .progress-bar__background {
      fill: none;
      // stroke: var(--tq-neutral-600);
      stroke-width: @progress-bar-stroke-width;
    }

    .progress-bar__progress {
      fill: none;
      stroke: @tq-neutral-900;
      stroke: @tq-neutral-900;
      stroke-dasharray: 100 100;
      stroke-dashoffset: 100;
      stroke-linecap: round;
      stroke-width: @progress-bar-stroke-width;
      transition: stroke-dashoffset 1s ease-in-out;
    }
  }
}
