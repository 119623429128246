.login-wrapper {
  padding: 48px 0;

  @media @screen1024 {
    padding: 32px 0;
  }

  .login-btn {
    margin-top: 32px;

    @media @screen767 {
      margin-top: 24px;
    }

    button.cs-button.ant-btn.ant-btn-lg {
      .button-xl;

      @media @screen767 {
        .button-l;
      }
    }
  }

  &.contact-page {
    padding: 32px 0;

    .login-block {
      max-width: 576px;
      padding: 24px 48px;

      @media @screen767 {
        padding: 0;
      }
    }

    .ant-form-item {
      margin: 0;
    }

    .form-group {
      textarea {
        min-height: 94px;

        @media @screen767 {
          min-height: 78px;
        }
      }
    }
  }

  .login-block {
    max-width: 528px;
    width: 100%;
    margin: auto;
    background: @tq-neutral-700;
    box-shadow: unset;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media @screen767 {
      box-shadow: none;
      max-width: 100%;
      background: transparent;
      padding: 0;
      border-radius: 0;
    }

    &.verification {
      .form-group {
        margin-bottom: 0;
      }
    }

    .header-section {
      display: flex;
      flex-direction: column;
      gap: 32px;

      &.small-gap {
        gap: 24px;
      }

      .header-description {
        font-size: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        h4 {
          .display-h4;
        }
        h5 {
          .display-h5;
        }
      }

      .header-info-text {
        font-size: 18px;
        line-height: 23px;
        text-align: center;

        & .strong {
          font-weight: 500;
        }
      }

      .header-image {
        position: relative;
        padding-top: 52.5%;

        img {
          object-fit: cover;
          object-position: center;
        }
      }

      .header-image-logo {
        position: relative;
        height: 100px;
        width: 150px;
        margin: 0 auto;
        img {
          object-fit: contain;
          object-position: center;
        }
      }
    }

    .ant-empty-description {
      .text-white-70;
    }

    .login-title {
      margin-bottom: 0;
      h3 {
        .text-black-90;
        @media @screen767 {
          margin-bottom: 4px;
        }
      }

      p {
        .paragraph-l;
        .text-black-70;
        a {
          .text-tiny;
          color: var(--tq-neutral-900);
          margin-top: 2px;
          text-decoration: underline;

          @media @screen767 {
            font: var(--tq-paragraph-m);
            font-weight: 500;
          }
        }
      }
    }

    .forget-pwd {
      text-align: right;
      margin-top: 16px;
    }
    .login-policy {
      font-size: 14px;
      line-height: 18px;
      a {
        font-weight: 500;
        text-decoration: underline;
        color: var(--tq-neutral-900);

        &:hover {
          color: var(--tq-neutral-900);
        }
      }
    }

    .login-footer {
      .text-tiny;
      .text-center;

      @media @screen767 {
        margin-top: 8px;
        font: var(--tq-paragraph-m);
        font-weight: 500;
        line-height: 22.5px;
      }

      a {
        .text-tiny;
        color: @tq-black-70;
        margin-top: 2px;
        text-decoration: underline;
        font-weight: 500;

        @media @screen767 {
          font: var(--tq-paragraph-m);
          font-weight: 500;
        }

        &.footer-link {
          text-decoration: none;
        }
      }
    }
    .ant-checkbox-wrapper {
      color: #000000;
    }
  }
}

.button-link {
  .text-tiny;
  color: @tq-neutral-900;
  font-weight: 500;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media @screen767 {
    font: var(--tq-paragraph-m);
    font-weight: 500;
  }
}

.account-setting {
  display: flex;
  flex-direction: row;
  // padding: 32px 0;

  @media @screen767 {
    padding: 0;
  }

  .account-page-title {
    display: none;

    @media @screen767 {
      display: block;
      padding: 16px;
      .heading-h5;
      border-bottom: 1px solid @tq-border-dark;
    }
  }

  .account-wrapper {
    .d-flex;
    flex-wrap: wrap;
    gap: 40px;
    .align-start;

    @media @screen1024 {
      gap: 24px;
    }

    .account-nav {
      background: @tq-neutral-200;
      box-shadow: @tq-shadow-s;
      border-radius: 8px;
      max-width: 268px;
      width: 100%;

      @media @screen767 {
        display: none;
      }

      ul {
        list-style: none;

        li {
          &:not(:last-of-type) {
            border-bottom: 1px solid @tq-border-bottom;
          }

          &.active {
            a {
              .text-n-900;
              .text-small-bold;
            }
          }

          a {
            .text-small;
            .d-flex;
            .align-center;
            .text-n-700;
            gap: 12px;
            padding: 16px 24px;
          }
        }
      }
    }

    .account-content-area {
      flex: 1;

      .account-info-block {
        margin: 0 auto;
        background: @tq-neutral-700;

        box-shadow: 0px 45px 18px rgba(78, 68, 54, 0.02),
          0px 25px 15px rgba(78, 68, 54, 0.08),
          0px 11px 11px rgba(78, 68, 54, 0.13),
          0px 3px 6px rgba(78, 68, 54, 0.15), 0px 0px 0px rgba(78, 68, 54, 0.15);
        border-radius: 16px;
        max-width: 528px;
        width: 100%;
        padding: 24px;

        @media @screen767 {
          min-height: 100vh;
          padding: 24px 0;
          box-shadow: none;
          background: transparent;
          max-width: 100%;
        }

        .button-wrapper {
          &.fixed-button {
            @media @screen767 {
              position: fixed;
              bottom: 0;
              background: @tq-neutral-200;
              padding: 16px;
              width: 100%;
              left: 0;
              bottom: 0;
              box-shadow: 0px -14px 6px rgba(53, 36, 10, 0.01),
                0px -4px 4px rgba(53, 36, 10, 0.05),
                0px -1px 2px rgba(53, 36, 10, 0.05);
              border-radius: 8px 8px 0px 0px;
            }
          }

          .button-xl;
        }

        &.faqs {
          max-width: 100%;
          padding-bottom: 4px;

          .faq-section-title {
            margin: 0;
          }

          .faq-section,
          .container {
            padding: 0;
            background: @tq-neutral-200;

            @media @screen767 {
              background: transparent;
            }
          }

          .container-marketing {
            max-width: 100%;
          }

          .ant-collapse {
            background: @tq-neutral-200;

            @media @screen767 {
              background: transparent;
            }
          }
        }

        .button-wrapper {
          margin-top: 32px;
        }

        .profile {
          .relative;
          margin-bottom: 24px;

          .ant-form-item {
            position: absolute;
            bottom: 2px;
            right: 2px;
            margin-bottom: 0;

            button {
              background-color: @tq-neutral-900;
              border-radius: 999px;
              font-size: 18px;
              color: #ffffff;
              height: 36px;
              width: 36px;
              min-width: 36px;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;

              &:hover {
                border-color: @tq-neutral-900;
              }

              @media @screen767 {
                height: 28px;
                width: 28px;
                min-width: 28px;
              }

              svg {
                @media @screen767 {
                  height: 16px;
                  width: 16px;
                  min-width: 16px;
                }
              }
            }
          }
        }

        .r-wrapper {
          .d-flex;
          .flex-vertical;

          @media @screen767 {
            flex-direction: row;
            gap: 8px;
          }
        }

        .form-group {
          .mb-16;

          &.r-50 {
            @media @screen767 {
              width: 50%;
            }
          }

          .label {
            .text-small-bold;
            .mb-8;

            @media @screen767 {
              .text-md-bold;
              .mb-6;
            }
          }
        }

        .update-profile-email {
          .ant-input-disabled,
          .ant-input[disabled] {
            color: @tq-neutral-700;
            background: @tq-neutral-100;
          }
        }
      }
    }
  }
}

.header-profile-popover.ant-popover {
  min-width: 383px;
  box-shadow: 0px 45px 18px rgba(78, 68, 54, 0.02),
    0px 25px 15px rgba(78, 68, 54, 0.08), 0px 11px 11px rgba(78, 68, 54, 0.13),
    0px 3px 6px rgba(78, 68, 54, 0.15), 0px 0px 0px rgba(78, 68, 54, 0.15);
  border-radius: 8px;
  padding: 0;

  @media @screen767 {
    left: 0 !important;
    min-width: 100%;
    top: 66px !important;
    bottom: 0;
    height: calc(100% - 45px);
    background: #fff;
    position: fixed;
    box-shadow: unset;
    border-radius: 0;
    overflow: auto;

    .ant-popover-content {
      height: 100%;

      .ant-popover-inner {
        height: 100%;

        .ant-popover-inner-content {
          height: 100%;

          .account-profile {
            height: 100%;
            display: flex;
            flex-direction: column;

            .account-profile-header {
              flex-shrink: 0;
              position: relative;
              &::before {
                content: '';
                height: 56px;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: #000;
              }
              .ant-avatar {
                height: 68px;
                width: 68px;
                border: 2px solid #000;
                position: relative;
                background: var(--tq-neutral-900);
                &::before {
                  content: '';
                  position: absolute;
                  inset: 0;
                  height: 100%;
                  width: 100%;
                  border: 2px solid #fff;
                  border-radius: 50%;
                }
                .ant-avatar-string {
                  line-height: 66px;
                }
              }
              .user-badge-image {
                img {
                  width: 100px;
                  height: auto;
                }
              }
            }
            .profile-menu {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  .ant-popover-inner {
    background-color: @tq-neutral-700;
    @media @screen767 {
      box-shadow: unset;
      background: transparent;
    }
    .ant-avatar {
      background-color: @tq-neutral-600;
    }
  }

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-arrow {
    display: none;
  }

  .account-profile-header {
    text-align: center;
    padding: 8px 24px 0 24px;
    .user-badge-image {
      margin-top: 8px;
      img {
        width: 100px;
        height: auto;
      }
    }
    .plan-name {
      padding: 10px 24px;
      font-size: 20px;
      line-height: 24px;
      font-family: var(--tq-secondary-font);
      border-block: 1px solid #000;
      margin-top: 12px;
      display: inline-block;
    }
    .plan-date {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.05em;
      text-align: center;
      font-family: var(--tq-secondary-font);
    }
    h5 {
      .display-h5;
      margin: 8px 0 4px 0;
      color: @tq-neutral-900;
      @media @screen767 {
        .display-h4;
      }
    }

    p {
      .text-small;
      color: #000;
      font-family: var(--tq-secondary-font);
      @media @screen767 {
        .text-lg;
        font-family: var(--tq-secondary-font);
      }

      &:not(:last-of-type) {
        .mb-8;
      }
    }
  }

  .profile-btn {
    padding: 32px 24px;

    @media @screen767 {
      padding: 16px;
    }

    button {
      padding: 0;
      background: transparent;
      color: #000;
      font-size: 20px;
      height: auto;
      &:hover {
        background: transparent;
        color: var(--tq-neutral-900);
      }
      svg {
        display: none;
      }
      span {
        font-family: var(--tq-secondary-font);
      }
    }
  }

  .profile-menu {
    padding-top: 32px;

    ul {
      list-style: none;

      li {
        border-top: 1px solid #bfbfbf;
        cursor: pointer;
        &:last-child {
          border-bottom: 1px solid #bfbfbf;
        }

        a {
          .text-small;
          .d-flex;
          .align-center;
          justify-content: space-between;
          color: #000;
          padding: 16px 24px;
          position: relative;
          font-family: var(--tq-secondary-font);
          @media @screen767 {
            padding: 10px 16px;
            font-size: 20px;
            font-family: var(--tq-secondary-font);
          }
          svg:first-child {
            display: none;
          }
          svg:last-child {
            color: var(--tq-neutral-900);
          }
          &:hover {
            .text-n-800;
          }

          .Caret {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.form-group-two-grid {
  .d-flex;

  gap: 16px;

  @media @screen767 {
    gap: 8px;
  }

  .form-group {
    width: 100%;
  }

  .ant-form-item {
    width: 100%;
  }
}

.form-group {
  .mb-16;

  &.mb-0 {
    .mb-0;
  }

  @media @screen767 {
    margin-bottom: 20px;
  }

  .ant-form-item {
    margin: 0;
  }

  .label {
    .text-small-bold;
    .mb-8;

    @media @screen767 {
      margin-bottom: 6px;
      .text-md-bold;
    }
  }

  a {
    .paragraph-s;
    color: var(--tq-neutral-900);
    margin-top: 2px;
    display: inline-block;
    font-weight: 500;

    @media @screen767 {
      margin-top: 0;
      display: block;
    }

    &:hover {
      color: var(--tq-neutral-800);
    }
  }
}

.ant-form-item-label {
  label {
    span {
      .text-md;

      @media @screen767 {
        .text-lg;
      }
    }
  }
}

.ant-picker {
  width: 100%;
  height: 44px;
  padding: 0 11px;
  background-color: #fbf7f1;
  border: 1px solid #ddd3c5;
  border-radius: 6px;

  .ant-picker-input > input {
    font-size: 18px;
    line-height: 100%;
    color: @tq-neutral-900;
  }
}

.autocomplete-places-popup {
  .ant-empty {
    padding: 8px 0;
    .ant-empty-image {
      svg {
        // used important! here to override inline ant design style.
        opacity: 1 !important;
      }
    }
    .ant-empty-description {
      .text-white-70;
    }
  }
}
.signup-checkbox {
  .ant-checkbox {
    align-self: flex-start;
  }
}
.delete-account-modal {
  &.ant-modal.custom-modal {
    border-radius: 0;
    .ant-modal-body {
      padding: 16px;
    }
    .ant-modal-content {
      background: #fff;
      h3 {
        .button-l;
        color: #000;
        margin-bottom: 16px;
      }

      .header {
        h3 {
          margin-bottom: 0;
        }
        .info {
          font-size: 16px;
          color: #000;
          text-align: left;
          margin-top: 0;
          span {
            color: #e10000;
          }
        }
      }
      .ant-input {
        border: 1px solid #dcdcdc;
      }
      .ant-flex {
        max-width: 350px;
        margin-left: auto;
      }
      .ant-btn {
        min-height: 40px;
        &:disabled {
          border: 0;
        }
      }
      .ant-btn-default {
        background: transparent;
        color: #000;
      }
    }
  }
}
.login-page {
  .wrapper {
    background: #fff;
    .manage-plan-header {
      p {
        color: #111111;
        font-weight: 600;
      }
    }
    .order-history {
      h2 {
        text-align: left;
        font-size: 24px;
        line-height: 1.2;
        font-weight: 500;
        font-family: var(--tq-primary-font);
        margin-bottom: 10px;
      }
      .billing-wrap {
        text-align: left;
        border: 1px solid #dcdcdc;
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 16px;
        p {
          font-weight: 600;
          font-size: 18px;
        }
        .ant-btn.ant-btn-link {
          padding: 0;
          color: #00749e;
        }
        .ant-tag {
          min-height: auto;
        }
      }
    }
    .payment-history .ant-divider-horizontal {
      -webkit-border-before: 1px solid #dcdcdc;
      border-block-start: 1px solid #dcdcdc;
      margin: 0;
    }
    .payment-history .payment-history-list .date-list-item {
      padding: 8px 0;
    }

    .payment-history .payment-history-list .ant-list-item:last-child {
      color: #00749e;
    }

    .total-usd {
      p {
        color: #111111;
      }
    }
    .call-back-wrapper {
      .call-back-block p {
        color: #111111;
      }
      .text-black-90 {
        color: #111111;
      }
      .login-block {
        max-width: 100%;
        padding: 0;
      }
    }
    .account-setting
      .account-wrapper
      .account-content-area
      .account-info-block {
      background: #fff;
      box-shadow: none;
      .display-h3,
      .form-group .ant-form-item .ant-form-item-label > label,
      .display-h5 {
        color: #111111;
      }
      .ant-input,
      .ant-select-selector,
      .ant-input-affix-wrapper {
        background-color: #fff;
        border-color: #dcdcdc;
        &.ant-input-disabled,
        .ant-select-disabled {
          background: var(--tq-white-70);
          color: #757575;
        }
      }
    }
    .form-group .react-tel-input .flag-dropdown {
      background: var(--tq-white-80);
      border-color: #dcdcdc;
      &.open {
        background: var(--tq-white-70);
        .selected-flag {
          background: var(--tq-white-70);
        }
      }
    }
    .btn-link {
      color: #00749e;
      text-decoration: none;
      font-weight: 400;
      cursor: pointer;
    }
    .form-group .react-tel-input .form-control {
      border-color: #dcdcdc;
    }
    .stripe-input {
      background-color: #fff;
      border-color: #dcdcdc;
    }
    .login-wrapper {
      .login-block {
        background: #fff;
        box-shadow: unset;
        .login-title {
          .payment-success-title {
            max-width: 350px;
            font-size: 48px;
            line-height: 1;
            margin: auto;
            @media @screen767 {
              max-width: 290px;
            }
            @media @screen540 {
              max-width: 255px;
            }
          }
          h3,
          p {
            color: #111111;
          }
        }

        .ant-input,
        .ant-select-selector,
        .ant-input-affix-wrapper {
          background-color: #fff;
          border-color: #dcdcdc;
          &.ant-input-disabled,
          .ant-select-disabled {
            background: var(--tq-white-70);
            color: #757575;
          }
        }
        .form-group .ant-form-item .ant-form-item-label > label,
        .display-h5,
        .ant-space-item .heading-h5 {
          color: #111111;
        }
        .login-policy {
          color: #757575;
          margin-top: 16px;
          margin-bottom: 32px;
          a {
            color: #00749e;
            text-decoration: none;
            font-weight: 400;
          }
        }
        .ant-space-item p {
          color: #757575;
        }
        .form-group a {
          color: #00749e;
          text-decoration: none;
          font-weight: 400;
        }
        .login-footer {
          color: #000000;
          a {
            color: #00749e;
            text-decoration: none;
            font-weight: 400;
          }
        }
      }
    }
  }
}
.cancel-subscription-modal {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  &.ant-modal .ant-modal-content .ant-modal-body {
    padding: 32px;
    @media @screen767 {
      padding: 24px;
    }
  }
  .ant-input,
  .ant-select-selector,
  .ant-input-affix-wrapper {
    background-color: #fff;
    border-color: #dcdcdc;
  }
  .mb-32 {
    @media @screen767 {
      margin-bottom: 16px;
    }
  }
  .text-black-90 {
    color: #000000;
  }
  .text-black-70 {
    color: #757575;
  }
  h3 {
    line-height: 30px;
    font-weight: 600;
  }
  .cancel-subscription-btn {
    .ant-btn {
      background: #fff;
      color: #000000;
      border: 1px solid #757575;
      &:hover {
        border: 1px solid var(--tq-neutral-800);
      }
    }
  }
  .subscription-benefits {
    .text-black-90;
    ul {
      list-style: none;
      margin-top: 16px;
      margin-bottom: 32px;
      @media @screen767 {
        margin-bottom: 24px;
      }
      li {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: flex-start;
        gap: 8px;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        svg {
          flex-shrink: 0;
        }
      }
    }
  }
}
