.skeleton {
  background-color: var(--tq-neutral-700);
  // The shine that's going to move across the skeleton:
  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0),
    rgba(#fff, 0.2),
    rgba(#fff, 0)
  );
  background-size: 40px 100%; // width of the shine
  background-repeat: no-repeat; // No need to repeat the shine effect
  background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
  animation: shine 1s ease infinite; // increase animation time to see effect in 'slow-mo'
  border-radius: 6px;
}

@keyframes shine {
  to {
    // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
    background-position: right -40px top 0;
  }
}
.skeleton-loader {
  .section-header {
    .sh-title {
      height: 33px;
      width: 170px;
    }
    .button-m {
      height: 33px;
      width: 80px;
    }
  }
  .slick-arrow {
    display: none !important;
  }
  .video-card {
    .vc-description {
      width: 100%;
      .part {
        height: 10px;
        width: 30%;
      }
      .category {
        height: 30px;
        width: 80%;
      }
      .paragraph {
        height: 14px;
        width: 100%;
      }
    }
  }
  .form-card {
    .skeleton {
      background: var(--tq-neutral-600);
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0)
      );
    }
    .vc-description {
      .icon {
        height: 20px;
        width: 20px;
      }
      .title {
        height: 20px;
        width: calc(100% - 20px);
      }
      .button {
        height: 42px;
        width: 100%;
      }
    }
  }
  &.subscription-header {
    .title {
      height: 26px;
      max-width: 400px;
      width: 100%;
    }
    .button {
      height: 40px;
      width: 150px;
    }
  }
  &.subscription-block-wrapper {
    .subscription-block {
      .sb-content {
        .secondary-text {
          height: 18px;
          width: 60%;
        }
        .title {
          width: 100%;
          height: 28px;
        }
        .button {
          height: 40px;
          width: 150px;
        }
      }
    }
  }
  &.video-info-section.podcast-info-section {
    .podcast-segment {
      width: 166px;
      height: 44px;
    }
    .left-controls {
      width: 100px;
      height: 16px;
    }
    .custom-control-wrap .controlitem-middle .play-pause-btn .skip-btn {
      height: 28px;
      border-radius: 50%;
    }
    .container .video-info-wrapper {
      .video-button-wrapper .videoaction .button {
        width: 80px;
        height: 38px;
      }
      .videoinfo-title {
        .ant-typography {
          width: 40px;
          height: 18px;
        }
        .display-h4 {
          width: 90px;
          height: 34px;
        }
      }
      .paragraph-wrapper {
        @media @screen1024 {
          order: 2;
        }
        .paragraph {
          width: 100%;
          height: 8px;
          margin-bottom: 4px;
          &.w-50 {
            width: 50%;
          }
        }
      }
    }
  }
  &.featured-video-card {
    .skeleton {
      @media @screen767 {
        background: var(--tq-neutral-500);
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0)
        );
      }
    }
    .fvc-details {
      bottom: 0;
      .fvc-description {
        .badge {
          height: 30px;
          width: 60px;
          border-radius: 30px;
          display: inline-block;
          @media @screen767 {
            height: 25px;
            width: 50px;
          }
        }
        .tag-carousel {
          width: 50%;
          height: 30px;
        }
        .heading {
          height: 48px;
          width: 30%;
          @media @screen767 {
            height: 38px;
          }
        }
        .date {
          height: 18px;
          width: 70px;
        }
        .description {
          width: 100%;
          height: 16px;
        }
      }
      .slider-button {
        .button {
          width: 100%;
          height: 52px;
        }
      }
    }
  }
  &.collection-items-section {
    .collection-item-container {
      .collection-item-list {
        .skeleton {
          background: var(--tq-neutral-600);
          background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.3),
            rgba(255, 255, 255, 0)
          );
        }
        .d-flex.gap-8 {
          .skeleton {
            height: 12px;
            width: 20px;
          }
        }
        .title {
          width: 100%;
          height: 20px;
        }
        p {
          width: 100%;
          height: 16px;
          margin-top: 4px;
        }
      }
    }
  }
  &.donate-page.inner-page {
    .donate-block {
      .donate-amount {
        .skeleton {
          background-color: var(--tq-moss-3);
          background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0)
          );
        }
        .form-title {
          height: 34px;
          width: 50%;
          margin: auto;
          margin-bottom: 8px;
        }
        .ant-typography {
          height: 18px;
          width: 100%;
        }
      }
      .donate-form {
        .skeleton {
          background-color: var(--tq-neutral-500);
          background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0)
          );
        }
        .label {
          height: 16px;
          width: 25%;
          display: block;
          margin-bottom: 6px;
        }
        .input {
          height: 44px;
          width: 100%;
        }
        .button {
          height: 52px;
          width: 50%;
        }
      }
    }
  }
  &.video-info-section {
    .player-wrapper {
      background: var(--tq-neutral-700);
    }
    .video-info-wrapper {
      .videoinfo-title {
        width: 50%;
        .badge {
          height: 26px;
          width: 60px;
          border-radius: 40px;
        }
        .display-h4 {
          width: 50%;
          height: 34px;
        }
      }
      .video-button-wrapper {
        .button {
          width: 116px;
          height: 54px;
          @media @screen767 {
            width: 100%;
          }
        }
      }
      .video-info-tags {
        .ant-tag {
          height: 30px;
          width: 60px;
          border-radius: 30px;
        }
      }
      .paragraph {
        height: 12px;
        width: 100%;
      }
    }
  }
  .collection-card {
    .collection-dec {
      .category {
        height: 20px;
        width: 50%;
        margin-bottom: 4px;
      }
      .part {
        height: 12px;
        width: 30px;
        margin-bottom: 4px;
      }
      .paragraph {
        width: 100%;
        height: 14px;
        margin-bottom: 4px;
      }
    }
  }
  &.tag-carousel {
    .ant-tag {
      width: 100px !important; /* important to overwrite slider inline width*/
      border-radius: 20px;
    }
  }
  &.featured-collection {
    .skeleton {
      background-color: var(--tq-neutral-600);
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0)
      );
    }
    .featured-collection-dec {
      .title {
        width: 170px;
        height: 48px;
        margin-bottom: 16px;
      }
      .featured-collection-video-count {
        height: 14px;
        width: 50px;
        @media @screen767 {
          margin-bottom: 16px;
        }
      }
      p {
        width: 100%;
        height: 16px;
        margin-bottom: 4px;
      }
      .button {
        width: 180px;
        height: 54px;
        margin-top: 32px;
        @media @screen767 {
          width: 100%;
        }
      }
    }
  }
  &.tag_grid {
    .ant-tag {
      width: 100px;
    }
  }
  .ctacard {
    .ctacard-dec {
      .title {
        height: 34px;
        width: 120px;
      }
      .date {
        height: 18px;
        width: 60px;
        margin: 12px 0;
      }
      .paragraph {
        width: 100%;
        height: 18px;
        margin-bottom: 4px;
      }
      .cta-button-wrapper {
        margin-top: 32px;
        gap: 8px;
        .button {
          height: 40px;
          flex: 1;
        }
      }
    }
  }
  &.ctaBanner-section {
    .cta-bnnner-block .cta-content {
      .title {
        height: 40px;
        width: 50%;
        margin: auto;
        margin-bottom: 12px;
      }
      p.description {
        height: 14px;
        width: 100%;
        margin-bottom: 8px;
      }
      .button {
        height: 48px;
        width: 200px;
        margin: auto;
      }
    }
  }
  &.information-block {
    .bg-n-700;
    .skeleton {
      background-color: var(--tq-neutral-600);
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0)
      );
    }
    .information-content {
      .display-h3 {
        height: 32px;
        width: 50%;
      }
      .description {
        height: 16px;
        width: 100%;
      }
      .button-wrapper {
        .button {
          height: 48px;
          width: 136px;
          @media @screen767 {
            flex: 1;
          }
        }
      }
    }
  }
  &.faq-section {
    .faq-section-title {
      .title {
        height: 32px;
        width: 100px;
      }
      .subtitle {
        height: 16px;
        width: 100%;
      }
    }
    .ant-collapse .ant-collapse-item {
      height: 58px;
      background: var(--tq-neutral-700);
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0)
      );
      background-size: 40px 100%;
      background-repeat: no-repeat;
      background-position: left -40px top 0;
    }
  }
  &.article-list-section {
    .skeleton {
      background-color: var(--tq-neutral-500);
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0)
      );
      background-size: 40px 100%;
      background-repeat: no-repeat;
      background-position: left -40px top 0;
    }
    .article-view-btn .button {
      height: 48px;
      max-width: 302px;
      width: 100%;
      margin: auto;
    }
    .heading {
      height: 40px;
      width: 150px;
      margin: auto;
      margin-bottom: 8px;
    }
    .paragraph {
      width: 100px;
      height: 16px;
      margin: auto;
    }
    .article-info {
      .badge {
        height: 20px;
        width: 80px;
      }
      p {
        height: 14px;
      }
    }
  }
}
