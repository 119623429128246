@import url(../variable.less);

/*typography css start here*/
.display-h0 {
  font: @tq-display-h0;
  letter-spacing: 0.02em;
}

.display-h1 {
  font: @tq-display-h1;
  letter-spacing: 0.01em;

  @media @minScreen1024 {
    letter-spacing: 0.02em;
  }
}

.display-h2 {
  font: @tq-display-h2;
  letter-spacing: 0.01em;

  @media @minScreen1024 {
    letter-spacing: 0.02em;
  }
}

.display-h3 {
  font: @tq-display-h3;
  letter-spacing: 0.01em;
}

.display-h4 {
  font: @tq-display-h4;
  letter-spacing: 0.01em;
}

.display-h5 {
  font: @tq-display-h5;
  letter-spacing: 0.01em;
}

.display-h6 {
  font: @tq-display-h6;
}

.heading-h1 {
  font: @tq-heading-h1;
}

.heading-h2 {
  font: @tq-heading-h2;
}

.heading-h3 {
  font: @tq-heading-h3;
}

.heading-h4 {
  font: @tq-heading-h4;
}

.heading-h5 {
  font: @tq-heading-h5;
}

.heading-h6 {
  font: @tq-heading-h6;
}

.heading-h7 {
  font: @tq-heading-h7;
}

.text-lg {
  font: @tq-text-lg;
  letter-spacing: 0.02em;
}

.text-lg-bold {
  font: @tq-text-lg-bold;
  letter-spacing: 0.02em;
}

.text-md {
  font: @tq-text-md;
}

.text-md-bold {
  font: @tq-text-md-bold;
  letter-spacing: 0.02em;
}

.text-md-kicker {
  font: @tq-text-md-kicker;
  text-transform: uppercase;
}

.text-small {
  font: @tq-text-small;
}

.text-small-bold {
  font: @tq-text-small-bold;
}

.text-small-kicker {
  font: @tq-text-small-kicker;
  text-transform: uppercase;
}

.text-tiny {
  font: @tq-text-tiny;
}

.text-tiny-bold {
  font: @tq-text-tiny-bold;
}

.text-tiny-kicker {
  font: @tq-text-tiny-kicker;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.text-meta {
  font: @tq-text-meta;
  letter-spacing: 0.02em;
}

.text-meta-bold {
  font: @tq-text-meta-bold;
  letter-spacing: 0.02em;
}

.button-xl {
  font: @tq-button-xl;
}

.button-l {
  font: @tq-button-l;

  @media @minScreen1024 {
    letter-spacing: 0.01em;
  }
}

.button-m {
  font: @tq-button-m;

  @media @minScreen1024 {
    letter-spacing: 0.02em;
  }
}

.button-s {
  font: @tq-button-s;

  @media @minScreen1024 {
    letter-spacing: 0.01em;
  }
}

.label-xxl {
  font: @tq-label-xxl;
  letter-spacing: 0.09em;
}

.label-xl {
  font: @tq-label-xl;
  letter-spacing: 0.09em;
  text-transform: uppercase;
}

.label-l {
  font: @tq-label-l;
  letter-spacing: 0.09em;
  text-transform: uppercase;
}

.label-m {
  font: @tq-label-m;
  letter-spacing: 0.09em;
  text-transform: uppercase;
}

.label-s {
  font: @tq-label-s;
  letter-spacing: 0.09em;
  text-transform: uppercase;
}

.label-xs {
  font: @tq-label-xs;
  letter-spacing: 0.09em;
  text-transform: uppercase;
}

.paragraph-s {
  font: @tq-paragraph-s;
  letter-spacing: 0.02em;
}

.paragraph-m {
  font: @tq-paragraph-m;
  letter-spacing: 0.02em;
}

.paragraph-l {
  font: @tq-paragraph-l;
  letter-spacing: 0.02em;
}

.paragraph-l-xl {
  font: @tq-paragraph-l-xl;
  letter-spacing: 0.02em;
}

.quote {
  font: @tq-quote;
  letter-spacing: 0.02em;
}

/*typography css end here*/
