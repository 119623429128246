@import url('../variable.less');

/*video card css start here*/
.video-grid {
  .video-card {
    max-width: 100%;
    min-width: unset;

    .video-image {
      padding-top: 56.177%;

      @media @screen767 {
        border-radius: 8px;
      }
    }

    .vc-description {
      span {
        letter-spacing: 0.02em;

        @media @screen767 {
          .text-small-bold;
        }
      }
    }
  }
  .load-more-btn {
    button {
      max-width: 302px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.video-select {
  @media @screen767 {
    .d-flex;
    .align-center;
    margin-left: 12px;
  }
}

.video-list-grid {
  .section-header {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--tq-border-dark);
    margin-bottom: 4px;
  }

  .video-list-item {
    .p-16;
    border-bottom: 1px solid var(--tq-border-dark);
    padding: 16px;
    height: 100%;

    .video-card.video-list {
      max-width: 100%;
    }
  }

  &.separator-off {
    .video-card.video-list,
    .section-header {
      border: 0;
    }
  }
}

.video-carousel {
  width: 100%;
  display: block;

  .slick-slide {
    margin: 0 8px 0 8px;

    @media @screen767 {
      margin: 0 4px;
    }
  }

  .slick-slide {
    min-width: calc(calc(100vw - 144px) / 5.1);
    max-width: calc(calc(100vw - 144px) / 5.1);
    @media @screen1450 {
      min-width: calc(calc(100vw - 144px) / 4.1);
      max-width: calc(calc(100vw - 144px) / 4.1);
    }

    @media @screen1150 {
      min-width: calc(calc(100vw - 105px) / 3.2);
      max-width: calc(calc(100vw - 105px) / 3.2);
    }

    @media @screen1024 {
      min-width: calc(calc(100vw - 105px) / 3);
      max-width: calc(calc(100vw - 105px) / 3);
    }

    @media @screen1024 {
      min-width: calc(calc(100vw - 160px) / 3);
      max-width: calc(calc(100vw - 160px) / 3);
    }

    @media @screen767 {
      min-width: calc(calc(100vw - 36px) / 2.1);
      max-width: calc(calc(100vw - 36px) / 2.1);
    }
  }

  .video-card {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }

  .slick-track {
    .d-flex;
  }
}

.video-card {
  min-width: 316px;
  max-width: 316px;
  width: 100%;
  position: relative;
  @media @screen1024 {
    min-width: 352px;
    max-width: 352px;
  }

  @media @screen767 {
    min-width: 160px;
    max-width: 160px;
    width: 100%;
  }
  .category-badge {
    position: absolute;
    left: 4px;
    top: 4px;
    .ant-badge {
      font-size: 14px;
      padding: 4px;
      border-radius: 4px;
      line-height: 1;
      display: block;
      @media @screen767 {
        font-size: 12px;
      }
    }
  }
  .ant-skeleton {
    width: 100%;

    .ant-skeleton-image {
      width: 100%;
      height: 178px;
      margin-bottom: 20px;
    }
  }

  .vc-description {
    .pt-12;
    .gap-8;
    display: inline-flex;
    .flex-vertical;
    gap: 8px;
    .align-start;

    .vc-title {
      .text-black-90;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      &:hover {
        .text-black-70;
      }
    }

    .tag-list {
      .text-black-80;
      a:hover {
        .text-n-800;
      }
    }

    @media @screen767 {
      .pt-8;
      .gap-6;
    }
    .topics-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }

  &.video-list {
    max-width: 100%;
    .d-flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid @tq-border-bottom;

    @media @screen767 {
      padding: 16px;
    }
    .category-badge {
      top: 24px;
      @media @screen767 {
        left: 24px;
      }
    }
    .ant-skeleton.ant-skeleton-element {
      width: 31.035%;
    }
    .video-list-action {
      ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        li {
          display: flex;
          align-items: center;
          &:not(:first-child)::before {
            content: '';
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background: var(--tq-black-70);
            margin: 0 8px;
            display: inline-block;
            opacity: 0.6;
          }
          a,
          .article-list-ctas {
            color: var(--tq-black-90);
            font: @tq-button-l;
            @media @screen767 {
              font: @tq-button-s;
            }
            &:hover {
              color: var(--tq-red);
            }
          }
        }
      }
    }
    .video-image {
      width: 31.035%;
      padding-top: 17.433%;
      height: 100%;

      @media @screen767 {
        width: 34.989%;
        padding-top: 19.826%;
      }

      .timestemp {
        @media @screen767 {
          right: 4px;
          top: 4px;
        }
      }
    }

    .vc-description {
      flex: 1;
      padding-top: 0;
      padding-left: 12px;
      gap: 6px;

      .topics-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
      }

      .vc-paragraph {
        .mb-0;
      }
    }
  }

  .video-image {
    width: 100%;
    padding-top: 56.33%;
    border-radius: 8px;
    overflow: hidden;
    @media @screen767 {
      border-radius: 4px;
    }

    .timestemp {
      position: absolute;
      right: 4px;
      top: 4px;
      padding: 4px;
      background: #111111;
      font: var(--tq-text-small);
      color: #fff;
      font-size: 14px;
      border-radius: 4px;

      @media @screen767 {
        font: @tq-text-tiny;
      }
    }

    .ant-slider {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      margin: 0;
      padding: 0;
      height: 4px;
      pointer-events: none;

      .ant-slider-handle {
        display: none;
      }

      .ant-slider-step {
        display: none;
      }
    }
  }
}

/*video card css end here*/
.video-info-tags {
  width: 100%;
  overflow: hidden;

  @media @screen1024 {
    width: 100%;
    order: 3;
  }

  @media @screen767 {
    margin: 0 -16px;
    padding-left: 16px;
  }
}

.video-button-wrapper {
  .d-flex;
  justify-content: flex-end;
  gap: 8px;
  flex: 1;

  @media @screen1024 {
    width: 100%;
    flex: 0 0 100%;
    justify-content: flex-start;
    order: 5;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  .videoaction {
    height: 66px;
    .d-flex;
    .flex-vertical;
    padding: 4px 12px 4px 8px;
    .justify-center;
    .align-center;
    overflow: hidden;
    text-align: center;
    min-width: 116px;
    text-align: center;
    max-height: 66px;
    @media @screen1024 {
      min-width: 34px;
      background: transparent;
      padding: 0;
      &:not(:disabled):not(.ant-btn-disabled):hover {
        background: transparent;
      }
    }

    span {
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
      .text-tiny;
      .mt-4;

      @media @screen1024 {
        font-size: 0;
        line-height: 0;
      }
    }

    &:disabled {
      opacity: 0.5;
      border: 0;
      color: @tq-neutral-900;
      .bg-n-400;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

/*video info css start here*/
.video-info-wrapper {
  margin-top: 24px;
  .d-flex;
  flex-wrap: wrap;
  gap: 16px;
  .paragraph-l;
  .text-black-80;

  @media @screen1024 {
    flex-direction: column;
    position: relative;
  }

  @media @screen767 {
    gap: 12px;
    .paragraph-m;
  }

  .video-info-tags {
    .ant-tag {
      .mr-8;
    }
  }

  .display-h4 {
    .text-black-90;
  }

  p {
    @media @screen1024 {
      order: 4;
    }
  }

  .videoinfo-title {
    .d-flex;
    .flex-vertical;
    .align-start;
    gap: 16px;
    flex: 1;
    @media @screen1024 {
      order: 1;
      width: 100%;
      gap: 16px;
      width: calc(100% - 62px);
    }
    @media @screen767 {
      gap: 12px;
    }
    .video-badge {
      background: transparent;
      border: 1px solid @tq-white-90;
    }
  }

  .video-avatar-group {
    .text-small-bold {
      .text-black-80;
    }
  }
}

.video-avatar-group {
  .d-flex;
  gap: 16px;
  .flex-shrink-0;
  overflow: auto;
  overflow-y: hidden;
  width: 100%;
  flex-wrap: wrap;

  .item {
    color: @tq-neutral-1000;
  }

  @media @screen1024 {
    order: 2;
  }
}

.video-info {
  .video-info-block {
    padding: 16px;
    border-radius: 8px;
    background: var(--tq-neutral-700);
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    @media @screen767 {
      padding: 12px;
    }
    @media @screen375 {
      gap: 12px;
    }
    .video-info-left {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 16px;
      @media @screen375 {
        gap: 12px;
      }
      .video-thumb-img {
        position: relative;
        width: 100px;
        padding-top: 56px;
        border-radius: 4px;
        overflow: hidden;
        flex-shrink: 0;
        cursor: pointer;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        @media @screen375 {
          width: 80px;
          padding-top: 48px;
        }
        .video-player-container {
          position: absolute;
          inset: 0;
        }
      }
      .info-text {
        font-size: 16px;
        margin-bottom: 2px;
        @media @screen375 {
          font-size: 14px;
        }
      }
      .video-name {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 115px;
        color: var(--tq-black-90);
        @media @screen375 {
          font-size: 18px;
        }
      }
    }

    .video-info-right {
      display: flex;
      gap: 16px;
      align-items: center;
      .cs-button {
        padding: 0;
        svg path {
          stroke: var(--tq-black-90);
        }
      }
      @media @screen375 {
        gap: 12px;
      }
    }
  }
}

/*video info css end here*/
