.video-info-section .video-button-wrapper .videoaction span {
  margin: auto;
}
.player-wrapper {
  padding-top: ~'min(56.25%, calc(100vh - 198px))';
  background-color: #000000;
  position: relative;
  min-height: 382px;

  .custom-player {
    position: absolute;
    inset: 0;
  }

  @media @screen1024 {
    height: auto;
    min-height: 382px;
    display: flex;
    align-items: center;
  }

  @media @screen767 {
    min-height: 340px;
  }

  @media @screen540 {
    height: auto;
    min-height: 210px;
    overflow: visible;
  }
}

.player-wrapper {
  &:hover {
    .custom-control-wrap {
      opacity: 1;
      visibility: visible;
    }

    .custom-overlay-without-controls {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.custom-control-wrap {
  opacity: 1;
  visibility: visible;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  .flex-vertical;
  z-index: 1;
  .d-flex;
  justify-content: space-between;
  padding: 16px 48px;
  transition: ease 0.25s;

  .ant-select-dropdown {
    min-width: 136px;
  }

  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-group-title {
    .text-meta-bold;
    .text-n-1000;
  }

  .ant-dropdown .ant-dropdown-menu {
    padding: 0;
    background: @tq-ivory;
    box-shadow: 0px 19px 8px rgba(78, 68, 54, 0.02),
      0px 5px 7px rgba(78, 68, 54, 0.13);
    border-radius: 8px;

    .ant-dropdown-menu-item-group-title {
      .text-meta-bold;
      .text-n-1000;
    }
  }

  .ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: @tq-neutral-900;
    color: @tq-black-90;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding: 0;
  }

  .ant-select .ant-select-arrow {
    opacity: 0;
  }

  @media @screen1150 {
    padding: 24px;
  }

  @media @screen767 {
    padding: 12px 16px 8px 16px;
  }

  .volume-section {
    .d-flex;
    .align-center;
    flex-direction: row;
    margin-right: 10px;

    @media @screen767 {
      display: none;
    }

    &:hover {
      .ant-slider {
        display: block;
        width: 128px;
        overflow: visible;
      }
    }

    .ant-slider {
      width: 0;
      height: 24px;
      margin: 0;
      padding: 8px 0;
      -webkit-transition: ease 0.25s;
      -o-transition: ease 0.25s;
      transition: ease 0.25s;
      overflow: hidden;

      .ant-slider-rail {
        height: 6px;
        background: @tq-neutral-0;
        border-radius: 0;
      }

      .ant-slider-track {
        height: 6px;
      }

      .ant-slider-handle {
        height: 16px;
        width: 16px;
        inset-block-start: 3px;

        &:before {
          height: 16px;
          width: 16px;
        }

        &:after {
          background: var(--tq-neutral-900);
          height: 16px;
          width: 16px;
          box-shadow: none;
        }

        // &:hover {
        //   &:after {
        //     box-shadow: 0 0 0 2px #1a1714;
        //   }
        // }
      }
    }

    .ant-slider-track {
      background-color: @tq-neutral-900;
      border-radius: 0;
    }
  }

  .controlitem-middle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;

    .play-pause-btn {
      .d-flex;
      .align-center;
      .justify-center;
      color: #fff;
      gap: 88px;

      @media @screen1024 {
        gap: 64px;
      }

      @media @screen767 {
        gap: 48px;
      }

      &.pointer {
        svg {
          width: 80px;
          height: auto;

          @media @screen1024 {
            width: 55px;
            height: auto;
          }

          @media @screen767 {
            width: 40px;
            height: auto;
          }
        }
      }

      svg {
        width: 48px;
        height: auto;

        @media @screen1024 {
          width: 32px;
          height: auto;
        }

        @media @screen767 {
          width: 24px;
          height: auto;
        }
      }
    }
  }

  .play-duration {
    .d-flex;
    justify-content: flex-start;
    .align-center;
    color: #fff;
    gap: 4px;

    .text-md {
      @media @screen767 {
        font: @tq-text-tiny;
      }

      &:last-child {
        opacity: 0.6;
      }
    }
  }

  .duration-bar {
    padding-bottom: 6px;
  }
}

.custom-title {
  position: absolute;
  top: 20px;
  right: 0;
  left: 20px;
  z-index: 5;
  .d-flex;
  padding: 5px;
  color: #fff;
  font-size: large;
}

.show-title.custom-title {
  opacity: 1;
}

.hide-title.custom-title {
  opacity: 0;
}

.custom-overlay-without-controls {
  position: absolute;
  bottom: 16px;
  right: 0;
  left: 0;
  z-index: 2;
  .d-flex;
  justify-content: flex-end;
  padding: 0 45px;
  transition: ease 0.25s;

  @media @screen1150 {
    padding: 0 24px;
  }

  @media @screen767 {
    padding: 0 16px;
    bottom: 44px;
  }

  &.show {
    bottom: 65px;
  }
}

.controls-items {
  .d-flex;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 0;

  @media @screen767 {
    padding-top: 8px;
  }
}

.controls-items {
  .ant-select-arrow .anticon > svg {
    vertical-align: top;
    height: 12px;
    color: #d9d9d9;
  }
}

.left-controls {
  .d-flex;
  .align-center;
}

.left-controls svg {
  cursor: pointer;
  margin-right: 10px;
  color: #fff;

  @media @screen767 {
    width: 18px;
    height: auto;
  }
}

.right-controls {
  .d-flex;
}

.right-controls svg {
  cursor: pointer;
  margin-left: 10px;
  color: #fff;

  @media @screen767 {
    margin-left: 16px;
    width: 24px;
    height: 24px;
  }
}

.controlitem-top {
  svg {
    color: #fff;

    @media @screen767 {
      width: 24px;
      height: auto;
    }
  }

  .video-dropdown {
    margin-left: 0;
  }
}

.custom-overlay-with-controls {
  margin-bottom: 16px;
}

.play-box {
  .d-flex;
  .align-center;
  justify-content: space-between;
  margin-right: 15px;

  @media @screen767 {
    margin-right: 8px;
  }
}

.playback-rate-selector {
  width: 32px;

  @media @screen767 {
    width: 24px;
    height: 24px;
  }
}

.player-popover {
  .ant-popover-content {
    padding: 0;

    .ant-popover-inner-content {
      padding: 0;

      .ant-select-selector {
        border: 0;
      }
    }
  }
}

.active-caption {
  border-bottom: 2px solid #d90000;
}

.chapters-wrapper {
  .d-flex;
  width: 100%;
  overflow-x: auto;
}

.chapters-item {
  margin: 5px 20px 0 0;
  padding: 10px;
  border: 1px solid grey;
  cursor: pointer;
}

.chapters-title {
  padding: 5px;
  color: #fff;
}

.active-chapters {
  background-color: rgb(48, 48, 48);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.158);
  color: white;
}

.playlist-item {
  width: 20%;
  margin: 10px;
}

.playlist-img {
  width: 100px;
  margin-right: 10px;
}

.share-item {
  justify-content: space-between;
  margin-top: 24px;
  gap: 8px;

  p {
    white-space: nowrap;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #fbf7f1;
    border: 1px solid #ddd3c5;
    border-radius: 8px;
    padding: 12px;
    height: 44px;
    .text-tiny;
    flex: 1;
  }

  .cs-button.ant-btn {
    padding: 12px 16px 16px;
    height: 44px;
    .button-s;
  }
}

// new css

.hide-btn {
  opacity: 0;
  z-index: -1;
  content-visibility: 'hidden';
  padding: 0;
  margin: 0;
  outline: 'none';
  border: none;
  line-height: 0;
  appearance: none;
}

.back-forward-btn {
  appearance: none;
  background-color: transparent;
  border: 0;
  padding: 0;
  width: 40px;
}

.back-forward-btn svg {
  height: 40px;
  width: auto;
}

.back-forward-btn:focus,
.back-forward-btn:active,
.back-forward-btn:focus-within {
  border: none;
  box-shadow: none;
  outline: 0;
}

.progress-wrap {
  position: relative;
  width: 100%;
}

.progress-wrap input[type='range'] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  outline: none;
  z-index: 3;
  height: 6px;
}

.progress-wrap progress[value] {
  appearance: none;
  border: none;
  width: 100%;
  height: 6px;
  overflow: hidden;
  /* for IE */
  color: black;

  @media @screen767 {
    height: 4px;
  }
}

.progress-wrap::-webkit-progress-value {
  border-radius: 0 6px 6px 0;
}

.progress-wrap::-moz-progress-bar {
  border-radius: 0 6px 6px 0;
}

.progress-wrap progress#progress-played[value] {
  position: absolute;
  z-index: 2;
  color: var(--tq-neutral-900);
}

.progress-wrap progress#progress-played[value]::-webkit-progress-bar {
  background-color: transparent;
}

.progress-wrap progress#progress-played[value]::-webkit-progress-value {
  background: var(--tq-neutral-900);
}

.progress-wrap progress#progress-played[value]::-moz-progress-bar {
  background: var(--tq-neutral-900);
}

.progress-wrap progress#progress-loaded[value] {
  position: absolute;
  z-index: 1;
  background: @tq-neutral-500;
  /* for IE */
  color: #b3b3b3;
}

.progress-wrap progress#progress-loaded[value]::-webkit-progress-bar {
  background-color: rgba(255, 255, 255, 0.35);
}

.progress-wrap progress#progress-loaded[value]::-webkit-progress-value {
  background: @tq-neutral-700;
}

.progress-wrap progress#progress-loaded[value]::-moz-progress-bar {
  background-color: rgba(255, 255, 255, 0.35);
}

.video-player-wrap {
  position: relative;
  padding-bottom: 56.25%;
  box-sizing: border-box;
}

.player {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
}

video {
  width: 100%;
  height: 100%;
}

.control-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  height: 100%;
  width: 100%;
}

.control-wrap {
  .d-flex;
  .align-center;
  justify-content: space-between;
  width: 350px;
}

.video-player-wrap .control-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-controls {
  // position: absolute;
  width: 100%;
  bottom: 0%;
  padding: 0px 24px;
  box-sizing: border-box;
}

.video-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.video-player-wrap.show-controls .control-wrap {
  opacity: 1;
}

.video-player-wrap.hide-controls .control-wrap {
  opacity: 0;
}

.video-player-wrap.show-controls .play-controls {
  opacity: 1;
}

.video-player-wrap.hide-controls .play-controls {
  opacity: 0;
}

.video-player-wrap.show-controls .control-overlay {
  opacity: 1;
}

.video-player-wrap.hide-controls .control-overlay {
  opacity: 0;
}

.control-wrap button:focus {
  border: none;
  box-shadow: none;
}

.control-wrap button:active {
  border: none;
  box-shadow: none;
}

.control-wrap button:focus-visible {
  outline: none;
}

.control-wrap button:focus-within {
  outline: none;
}

.dropdown {
  position: absolute;
  right: 30px;
  top: 85px;
  text-align: right;
}

.caption-wrap {
  position: relative;
  display: inline-block;
}

.caption-popover-wrap {
  position: absolute;
  bottom: 50px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 29px 48px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  min-width: 80px;
  z-index: 1;
  border-radius: 8px;
  text-align: left;
  padding: 8px 10px;
}

.off-btn {
  background: transparent;
  border: 2px solid transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  width: 100%;
  text-align: left;
  padding: 5px;
}

.caption-popover-title {
  margin: 5px 0px;
}

.caption-popover-title:last-child {
  margin: 0px 0px;
}

.caption-popover-wrap .caption-popover-title button:focus {
  border: 2px solid #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
}

.caption-popover-wrap .caption-popover-title button:active {
  border: 2px solid #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
}

.caption-popover-wrap .caption-popover-title button:focus-within {
  outline: none;
}

.caption-popover-wrap .caption-popover-title button:focus-visible {
  outline: none;
}

.mobile-controls {
  @media @screen767 {
    &:hover {
      .custom-control-wrap {
        .controls-items,
        .controlitem-top,
        .duration-bar {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .custom-control-wrap {
      opacity: 1;
      visibility: visible;

      .controls-items,
      .controlitem-top,
      .duration-bar,
      .custom-overlay-with-controls {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

.poster-image {
  position: absolute;
  inset: 0;
  overflow: hidden;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
}
.video-info-section {
  &.podcast-info-section {
    max-width: 640px;
    margin: auto;
    position: relative;
    .container {
      padding: 0;
      @media @screen767 {
        padding: 0 16px;
      }
      .video-info-wrapper {
        .videoinfo-title {
          gap: 4px;
        }
        .video-button-wrapper {
          width: 100%;
          flex: 0 0 100%;
          justify-content: space-between;
          padding: 4px 16px;
          position: relative;
          order: 0;
          .videoaction {
            flex-direction: row;
            background: transparent;
            min-width: auto;
            span {
              text-overflow: inherit;
              overflow: visible;
              font-weight: 500;
            }
          }
        }
      }
    }
    .player-wrapper {
      padding-top: 65%;
      background-color: #000000;
      position: relative;
      min-height: 414px;
    }

    .hide-podcast-segment {
      display: none;
    }

    .podcast-segment {
      width: auto;
      text-align: right;
      position: absolute;
      right: 16px;
      top: 16px;
      z-index: 2;
      .ant-segmented-thumb {
        border-radius: 30px;
        color: #000000;
      }
      // .ant-segmented-item {
      //   color: @tq-neutral-1000;
      //   &:hover:not(.ant-segmented-item-selected):not(
      //       .ant-segmented-item-disabled
      //     ) {
      //     color: @tq-neutral-1000;
      //   }
      //   &.ant-segmented-item-selected {
      //     color: @tq-white-90;
      //   }
      // }
      .ant-segmented .ant-segmented-item {
        border-radius: 30px !important;
      }
      .ant-segmented-item:hover:not(.ant-segmented-item-selected):not(
          .ant-segmented-item-disabled
        ) {
        color: #fff;
      }
      .ant-segmented.podcast-segmented {
        background: var(--tq-neutral-700);
        border-radius: 30px;
        overflow: hidden;
        .ant-segmented-item-label {
          font-size: 16px;
          min-height: 40px;
          line-height: 40px;
          font-weight: 500;
        }
        .ant-segmented-item-selected {
          border-radius: 30px;
          background: var(--tq-neutral-0);
        }
      }
    }
    .custom-control-wrap {
      position: relative;
      padding: 16px;
      background: transparent;
      .podcast-dropdown {
        .video-dropdown {
          display: flex;
          align-items: center;
          position: absolute;
          right: 26%;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
          @media @screen767 {
            right: 0;
            position: relative;
            top: 0;
            transform: unset;
            margin: auto;
          }
          svg {
            width: 24px;
          }
        }
      }
      .controls-items {
        padding-top: 0;
      }
      .left-controls {
        .volume-section {
          svg {
            width: 24px;
          }
          .ant-slider-track,
          .ant-slider .ant-slider-handle::after {
            background: var(--tq-neutral-900);
          }
          .ant-slider-track {
            border-radius: 16px;
          }
          .custom-control-wrap .volume-section .ant-slider-track {
            background: var(--tq-neutral-900);
            border-radius: 4px;
          }
          .ant-slider .ant-slider-rail {
            background: var(--tq-neutral-0);
            border-radius: 4px;
          }
        }
      }
      .controlitem-top {
        display: none;
      }
      .controlitem-middle {
        position: relative;
        top: 0;
        transform: none;
        .play-pause-btn {
          gap: 28px;
          position: relative;
          .skip-btn {
            width: 28px;
            height: auto;
          }
          .playback-rate-content {
            position: absolute;
            right: calc((100% - 200px) / 2 - 50px);
            @media @screen767 {
              right: 12px;
            }
          }
        }
        .play-pause-btn.pointer {
          height: 64px;
          width: 64px;
          border-radius: 50%;
          background: var(--tq-neutral-700);
          svg {
            width: 32px;
            height: auto;
          }
        }
      }
    }
  }
}
