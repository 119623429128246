@import url(../variable.less);

footer {
  padding: 28px 0;
  background: var(--tq-footer-bg);
  @media @screen767 {
    padding: 23px 0;
  }
  .footer-wrapper {
    .d-flex;
    .align-center;
    justify-content: space-between;

    @media @screen767 {
      .flex-vertical;
      .align-start;
    }

    .footer-logo {
      display: block;

      svg {
        height: 32px;
        width: auto;
      }
    }
  }

  .footer-bottom {
    .d-flex;
    .align-center;
    justify-content: center;
    color: @tq-white-80;
    .text-meta;
    line-height: 18px;

    svg {
      margin-right: 2px;
    }
  }

  .footer-menu {
    text-align: right;

    @media @screen767 {
      text-align: left;
      margin: 24px 0;
    }

    ul {
      .d-flex;
      margin: 0 -12px;
      list-style: none;
      justify-content: flex-end;

      @media @screen767 {
        justify-content: flex-start;
        padding: 0;
        flex-flow: column;
      }

      li {
        padding: 0 12px;

        &:not(:last-child) {
          @media @screen767 {
            margin-bottom: 10px;
          }
        }

        a {
          color: @tq-white-80;
          text-decoration: underline;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;

          &:hover {
            color: @tq-white-70;
          }
        }
      }
    }
  }
}
