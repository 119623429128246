.article-list-section {
  padding-top: 16px;
  padding-bottom: 36px;
  background: #fff;
  .section-header {
    text-align: center;
    h3 {
      .display-h3;
      color: #2b2b2b;
      margin-bottom: 8px;
    }
    p {
      color: #727272c7;
      .text-tiny;
      font-weight: 700;
      line-height: 16.8px;
      letter-spacing: 0.4em;
      text-align: center;
    }
  }
  .article-view-btn button {
    max-width: 302px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
  }
}
.article-list-card {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  .article-card-img {
    position: relative;
    padding-top: 134px;
    width: 193px;
    flex-shrink: 0;
    @media @screen375 {
      width: 50%;
      padding-top: 50%;
    }
    img {
      object-fit: cover;
    }
  }
  .article-info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    p {
      font-size: 16px;
      color: #1a1a19;
      line-height: 24px;
    }
    .article-auth-name {
      font-size: 12px;
      line-height: 1.4;
      color: #1472a0;
    }
    .article-auth-date {
      font-size: 12px;
      font-family: var(--tq-primary-font);
      font-weight: 400;
    }
  }
}
