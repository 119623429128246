/*category card css start here*/
.category-list {
  .category-card {
    max-width: 100%;
    min-width: 100%;
    padding-top: 148px;
    margin-bottom: 16px;

    @media @screen767 {
      padding-top: 84px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .category-title {
      .topic {
        font: @tq-display-h2;
        max-width: 100%;

        @media @screen767 {
          padding-bottom: 12px;
          font: @tq-display-h4;
        }

        &:before {
          width: 58px;
          border-bottom: 5px solid;

          @media @screen767 {
            width: 34px;
            border-bottom: 4px solid;
          }
        }
      }
    }
  }
  .button-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .cs-button span {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 84%;
    }
    .ant-btn {
      min-width: 302px;
      max-width: 302px;
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      span.ant-avatar {
        position: absolute;
        right: 23px;
        bottom: 13px;
      }
    }
    .primary-list-devider {
      span {
        border-bottom: 1px solid @tq-white-90;
      }
    }
    .ant-btn.secondary-list-devider {
      border-bottom: 1px solid @tq-white-90;
      border-radius: 0;
      text-align: start;
    }
    .ant-divider {
      border-block-start: 1px solid @tq-white-90;
    }
  }
}

.category-card {
  max-width: 248px;
  min-width: 248px;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;
  padding-top: 324px;
  .relative;

  @media @screen1024 {
    padding-top: 260px;
  }

  @media @screen767 {
    max-width: 160px;
    min-width: 160px;
    padding-top: 208px;
  }

  &:hover {
    color: inherit;

    .category-title {
      .topic::before {
        width: 80px;
      }
    }
  }

  &.category-grid {
    max-width: 256px;
    min-width: 256px;
    padding-top: 63.274%;
    display: block;

    &.category-card {
      cursor: pointer;
    }

    @media @screen767 {
      padding-top: max(63.29%, 106px);
    }

    .category-title {
      padding: 20px;

      @media @screen767 {
        padding: 16px;
      }

      h5 {
        padding-bottom: 12px;
      }

      .topic {
        padding-bottom: 12px;

        @media @screen767 {
          font: @tq-display-h5;
        }
      }
    }
  }

  &.categorylist {
    max-width: 100%;
    min-width: 100%;
    padding: 0;

    .category-title {
      position: relative;
      padding: 32px 24px;

      @media @screen767 {
        padding: 24px 12px;
      }

      .topic {
        @media @screen767 {
          padding-bottom: 12px;
        }
      }
    }
  }

  &.category-landing {
    border-radius: 0;
    max-width: 100%;
    min-width: 100%;
    display: block;
    padding-top: 228px;

    @media @screen1024 {
      padding-top: 176px;
    }

    @media @screen767 {
      padding-top: 160px;
    }

    .category-title {
      padding: 32px 48px;

      @media @screen1024 {
        padding: 24px;
      }

      @media @screen767 {
        padding: 16px;
      }

      .topic {
        font: @tq-display-h1;
        letter-spacing: 0.02em;

        @media @screen1024 {
          .display-h2;
        }

        @media @screen767 {
          font: @tq-display-h3;
          letter-spacing: 0.01em;
          margin-bottom: 12px;
        }

        &:before {
          width: 62px;

          @media @screen767 {
            width: 36px;
          }
        }
      }
    }
  }

  .category-title {
    .absolute;
    .h-full;
    .w-full;

    .d-flex;
    .align-end;
    .p-16;
    left: 0;
    top: 0;

    .topic {
      .m-0;
      padding-bottom: 16px;
      width: 100%;
      .relative;
      font: @tq-display-h4;
      letter-spacing: 0.01em;

      @media @screen767 {
        font: @tq-display-h4;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 42px;
        border-bottom: 5px solid;
        border-color: inherit;
        -webkit-transition: all 0.4s ease-in-out;
        -moz-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;

        @media @screen767 {
          width: 30px;
          border-bottom: 4px solid;
        }
      }
    }
  }
}

.category-slider {
  .slick-slide {
    margin: 0 8px;

    @media @screen767 {
      margin: 0 6px;
    }
  }

  .slick-slide {
    min-width: calc(calc(100vw - 175px) / 6.1);
    max-width: calc(calc(100vw - 175px) / 6.1);

    @media @screen1450 {
      min-width: calc(calc(100vw - 175px) / 5.1);
      max-width: calc(calc(100vw - 175px) / 5.1);
    }

    @media @screen1150 {
      min-width: calc(calc(100vw - 140px) / 4.1);
      max-width: calc(calc(100vw - 140px) / 4.1);
    }

    @media @screen1024 {
      min-width: calc(calc(100vw - 130px) / 3.1);
      max-width: calc(calc(100vw - 130px) / 3.1);
    }

    @media @screen1024 {
      min-width: calc(calc(100vw - 214px) / 3.1);
      max-width: calc(calc(100vw - 214px) / 3.1);
    }

    @media @screen767 {
      min-width: calc(calc(100vw - 39px) / 3.1);
      max-width: calc(calc(100vw - 39px) / 3.1);
    }

    @media @screen540 {
      min-width: calc(calc(100vw - 39px) / 2.1);
      max-width: calc(calc(100vw - 39px) / 2.1);
    }
  }

  .category-card {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    display: block;

    .category-title {
      padding: 24px;

      @media @screen1024 {
        padding: 20px;
      }

      @media @screen767 {
        padding: 16px;
      }
    }
  }
}

.category-grid {
  .category-card {
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .section-header {
    margin-bottom: 16px;

    @media @screen1024 {
      margin-bottom: 20px;
    }

    @media @screen767 {
      margin-bottom: 16px;
    }
  }
}

/*category card css end here*/
