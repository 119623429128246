.plan-price-section {
  min-height: calc(100vh - 119px);
  background: #fff;
  padding: 80px 0;
  @media @screen767 {
    padding: 16px 0;
  }
  .plan-price-wrapper {
    max-width: 620px;
    width: 100%;
    margin: auto;
    .payment-list-wrapper {
      margin-bottom: 28px;
      @media @screen767 {
        margin-bottom: 16px;
      }
    }
  }
  .plan-thank-you-wrapper {
    max-width: 674px;
    width: 100%;
    padding: 70px 16px;
    background: #ebebeb;
    margin: auto;
    text-align: center;
    border-radius: 16px;
    @media @screen767 {
      background: transparent;
      padding: 30px 0;
    }
    h1 {
      max-width: 466px;
      width: 100%;
      margin: auto;
      margin-bottom: 16px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      max-width: 314px;
      margin: auto;
      margin-top: 16px;
    }
    .ant-divider {
      border-block-start: 1px solid #0000001f;
      margin: 40px 0;
    }
    .ant-btn {
      font-weight: 600;
      max-width: 311px;
      width: 100%;
    }
    .redirect-button {
      font-size: 16px;
      line-height: 16px;
      &.ant-btn {
        font-weight: 600;
        border: 2px solid #ffb700;
        background: transparent;
        color: #000;
      }
    }
  }
}
.price-slider-section {
  background: #272727;
  color: #fff;
  padding: 16px;
  .ant-form-item {
    margin-bottom: 0;
  }
  .price-input {
    justify-content: space-between;
    margin-bottom: 18px;
    .ant-input-number-prefix {
      color: #454545;
      font-size: 18px;
    }
    & > div {
      color: #fff;
    }
    .ant-form-item {
      max-width: 300px;
      width: 100%;
    }
    .ant-input-number-affix-wrapper {
      width: 100%;
      background: #212121;
      border: 1px solid #454545;
      color: #ffffff;
      border-radius: 0;

      .ant-input-number-prefix {
        position: relative;
        top: 1px;
      }
      input {
        background: #212121;
        color: #ffffff;
        height: 40px;
      }
    }
    .ant-form-item-explain {
      text-align: right;
    }
  }
  .ant-slider:hover .ant-slider-rail {
    background-color: #d8e5e64d;
  }
  .ant-slider .ant-slider-handle::after {
    background-image: url(/images/paywall.svg);
    background-position: center;
    background-repeat: no-repeat;
  }
  .ant-slider .ant-slider-handle:focus::after {
    box-shadow: 0px 0px 13.4px 0px #ff550080;
  }
  .ant-slider .ant-slider-handle::before {
    display: none;
  }
  .ant-slider .ant-slider-handle:hover::after {
    box-shadow: 0px 0px 13.4px 0px #ff550080;
  }
  .price-btn {
    margin-top: 32px;
  }
  .manage-plan-switch {
    margin-top: 38px;
  }
  .ant-switch-inner {
    border: 1px solid;
  }
}
.payment-list-wrapper {
  background: #272727;
  color: #fff;
  .payment-list-header {
    padding: 16px;
    font-family: var(--tq-secondary-font);
    font-size: 18px;
    line-height: 1;
    border-bottom: 1px solid #ffffff4d;
  }
  ul {
    padding: 0 12px 12px 12px;
    counter-reset: counter;
    li {
      counter-increment: counter;
      display: flex;
      justify-content: space-between;
      padding: 16px;
      font-family: var(--tq-secondary-font);
      font-weight: 500;
      font-size: 18px;
      line-height: 1;
      position: relative;
      padding-left: 44px;
      @media @screen767 {
        padding: 14px 16px;
        padding-left: 44px;
      }
      &.active {
        background: @tq-neutral-900;
        box-shadow: 0px 0px 13.4px 0px #ff550080;

        box-shadow: 0px 0px 6.3px 0px #ffffff80 inset;
      }
      div {
        font-family: var(--tq-secondary-font);
        span {
          text-transform: lowercase;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #ffffff4d;
      }
      &::before {
        content: counter(counter) '';
        position: absolute;
        left: 16px;
      }
    }
  }
}
