.featured-video-card {
  padding-top: 37.5%;
  min-height: 410px;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  .d-flex;
  align-items: flex-end;
  z-index: 0;

  @media @screen767 {
    padding: 0;
    .flex-vertical;
    border-radius: 0;
  }

  &.featured-carousel.center-false {
    .fvc-image {
      &::before {
        content: '';

        height: calc(100% - 86px);
        bottom: 0;
        left: 0;
        width: 100%;
        position: absolute;
        z-index: 1;
        display: block;

        @media @screen767 {
          display: none;
        }
      }
    }
  }

  .fvc-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    @media @screen767 {
      position: relative;
      height: 420px;
    }

    &::before {
      content: '';
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 4.55%,
        rgba(0, 0, 0, 0.85) 100%
      );
      height: calc(100% - 86px);
      bottom: 0;
      left: 0;
      width: 100%;
      position: absolute;
      z-index: 1;

      @media @screen767 {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          @tq-neutral-700 100%
        );
      }
    }
  }

  .tag_grid {
    @media @screen767 {
      margin-bottom: 12px;
    }
  }

  .fvc-details {
    padding: 0 40px 32px 40px;
    width: 100%;
    .d-flex;
    align-items: flex-end;
    justify-content: space-between;
    position: absolute;
    z-index: 2;

    @media @screen1024 {
      padding: 0 24px 24px 24px;
    }

    @media @screen767 {
      padding: 0 16px 24px 16px;
      background: @tq-neutral-700;
      .flex-vertical;
      position: relative;
    }
    .tag-carousel {
      .ant-tag {
        .bg-n-0;
        .text-tiny;
        color: var(--tq-ivory);
        line-height: 28px;
      }
    }
    .slider-button {
      max-width: 244px;
      width: 100%;
      margin-bottom: 8px;

      @media @screen767 {
        max-width: 100%;
        .d-flex;
        flex-direction: row-reverse;
        gap: 8px;
      }

      button {
        font-size: 18px;

        @media @screen767 {
          flex: 1;
          width: 50%;
          height: 48px;
          font: @tq-button-m;
        }

        svg {
          @media @screen767 {
            width: 20px;
            .flex-shrink-0;
          }
        }

        &:last-child {
          margin-top: 16px;

          @media @screen767 {
            margin-top: 0;
          }
        }
      }
    }

    .fvc-description {
      max-width: 500px;
      width: 100%;

      @media @screen767 {
        max-width: 100%;
        margin-top: -146px;
        position: relative;
      }

      .ant-badge {
        margin-bottom: 12px;

        @media @screen767 {
          margin-bottom: 8px;
        }
        &.premium-tag {
          margin-left: 4px;
          top: 4px;
        }
      }

      .heading {
        .display-h2;
        letter-spacing: 0.01em;
        color: @tq-white-90;

        @media @screen767 {
          margin-bottom: 8px;
        }
      }

      .sub-heading {
        .text-small-bold;

        &:hover {
          .text-n-0;
        }

        @media @screen767 {
          .text-lg-bold;
        }
      }

      .date {
        .text-small;
        .text-white-70;
        margin-bottom: 7px;

        @media @screen767 {
          .text-md;
        }
      }

      .description {
        .paragraph-m;
        margin-bottom: 12px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        display: -webkit-box;

        @media @screen767 {
          -webkit-line-clamp: 3;
        }
      }
    }
  }
}

.featured-carousel {
  &.articalcarousel {
    .slick-slide.slick-active.slick-current {
      .section-title {
        opacity: 1;
        visibility: visible;
      }
    }
    .comment-count {
      display: flex;
      gap: 8px;
      @media @screen767 {
        justify-content: space-between;
        padding-bottom: 8px;
        border-bottom: 1px solid var(--tq-border-light);
        margin-bottom: 8px;
      }
    }
    .fvc-details .fvc-description .heading {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .fvc-details .slider-button {
      max-width: 280px;
      display: flex;
      flex-direction: row;
      gap: 8px;
      @media @screen767 {
        max-width: 100%;
        flex-direction: row;
      }
      button:last-child {
        margin: 0;
      }
      button {
        font-size: 0;
        @media @screen767 {
          font-size: 16px;
          flex-direction: column;
          height: auto;
          padding: 8px 0;
        }
      }
    }
    .section-title {
      display: none;
      @media @screen767 {
        display: flex;
      }
      .display-h5 {
        color: var(--tq-black-90);
        @media @screen767 {
          color: var(--tq-white-90);
        }
      }
      .ant-btn {
        color: var(--tq-black-90);
        padding: 0;
        display: flex;
        align-items: center;
        gap: 8px;
        font: var(--tq-button-m);
        @media @screen767 {
          color: var(--tq-white-90);
          gap: 4px;
        }
        svg {
          path {
            stroke: var(--tq-black-90);
            @media @screen767 {
              stroke: var(--tq-white-90);
            }
          }
        }
      }
    }
  }
  @media @screen767 {
    padding: 0;
    background: @tq-neutral-700;
  }

  .display-h4 {
    position: relative;

    @media @screen1024 {
      padding: 0 24px;
    }

    @media @screen767 {
      color: @tq-neutral-100;
      margin-bottom: 12px;
      position: relative;
      z-index: 1;
      padding: 0;
    }
  }

  .slick-track {
    margin: auto;
  }

  &.center-false {
    position: relative;

    @media @screen767 {
      overflow: hidden;
      padding: 24px 16px 0 16px;
    }

    &:before {
      @media @screen767 {
        content: '';
      }
    }

    &:after {
      @media @screen767 {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
      }
    }

    .ant-carousel {
      position: relative;
      z-index: 1;
    }

    .fvc-image {
      @media @screen767 {
        height: 0;
        padding-top: 56%;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        z-index: 1;
      }

      &::before {
        display: none;
      }
    }

    .slick-dots {
      @media @screen767 {
        background: transparent;
        padding: 0;
      }
    }

    .fvc-details {
      @media @screen767 {
        background: transparent;
        padding: 16px 0;
      }

      .fvc-description {
        @media @screen767 {
          margin-top: 0;
        }
      }
    }

    .ant-carousel {
      .slick-arrow {
        &.slick-prev {
          left: -20px;
        }

        &.slick-next {
          right: -20px;
        }
      }
    }
  }

  .slick-slide {
    @media @screen767 {
      margin: 0;
      visibility: hidden;
    }

    .featured-video-card {
      opacity: 0.4;
      -webkit-transform: scale(0.8682);
      -ms-transform: scale(0.8682);
      transform: scale(0.8682);
      -webkit-transform-origin-x: 89%;
      transition: transform 0.3s ease;
      padding-top: 40.9%;

      @media @screen767 {
        padding-top: 0;
        opacity: 1;
        transform: scale(1);
        -webkit-transform-origin-x: unset;
      }

      .paragraph-m {
        display: none;

        @media @screen767 {
          display: block;
        }
      }
    }

    &.slick-current {
      opacity: 1;
      visibility: visible;

      .featured-video-card {
        opacity: 1;
        transform: scale(1);

        @media @screen767 {
          opacity: 1;
        }

        .paragraph-m {
          display: block;
        }

        .fvc-details .fvc-description .date {
          margin-bottom: 4px;
        }
      }

      + .slick-slide {
        .featured-video-card {
          -webkit-transform-origin-x: 10%;

          @media @screen767 {
            -webkit-transform-origin-x: unset;
          }
        }
      }

      .featured-video-card {
        padding-top: 40.644%;

        @media @screen767 {
          padding: 0;
        }
      }
    }
  }
  .section-header {
    @media @screen767 {
      margin-bottom: 0;
    }
    .sh-top {
      @media @screen767 {
        margin-bottom: 0;
        padding-top: 24px;
      }
    }
    .sh-title {
      @media @screen767 {
        color: var(--tq-white-90);
      }
    }
    .vc-paragraph {
      @media @screen767 {
        color: var(--tq-white-90);
      }
    }
  }

  .ant-carousel {
    .slick-arrow {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background-color: @tq-neutral-900;
      box-shadow: 0px 45px 18px rgba(78, 68, 54, 0.02),
        0px 25px 15px rgba(78, 68, 54, 0.08),
        0px 11px 11px rgba(78, 68, 54, 0.13), 0px 3px 6px rgba(78, 68, 54, 0.15),
        0px 0px 0px rgba(78, 68, 54, 0.15);
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(-34%);

      &:hover {
        background-color: @tq-neutral-800;
      }

      &.slick-prev {
        left: 32px;
        z-index: 1;
        background-color: @tq-neutral-900;
        background-image: url('/images/slick-left.svg');
        background-repeat: no-repeat;
        background-position: center;
      }

      &.slick-next {
        right: 32px;
        background-color: @tq-neutral-900;
        background-image: url('/images/slick-right.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .slick-dots {
      position: relative;
      bottom: 0;
      padding-bottom: 16px;

      li {
        width: auto;
        margin: 0 2px;
        height: auto;

        &.slick-active {
          width: auto;

          button {
            background: @tq-neutral-0;
          }
        }

        button {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          background: @tq-neutral-500;
        }
      }
    }
  }
}

.featured-collection {
  .container-rnp {
    @media @screen1024 {
      padding: 0 24px;
    }

    @media @screen767 {
      padding: 0;
    }
  }

  .featured-collection-wrapper {
    .d-flex;
    border-radius: 16px;
    overflow: hidden;

    @media @screen767 {
      .flex-vertical;
      padding: 32px 24px;
      border-radius: 0;
    }

    .featured-collection-img {
      padding-top: 25.596%;
      width: 48.81%;
      .flex-shrink-0;

      @media @screen1024 {
        padding-top: max(25.596%, 354px);
      }

      @media @screen767 {
        width: 100%;
        padding-top: 55.05%;
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }

  .featured-collection-dec {
    padding: 64px 20px 64px 60px;
    max-width: 564px;
    width: 100%;
    .d-flex;
    .flex-vertical;
    .align-start;
    .justify-center;
    .text-n-200;

    @media @screen1024 {
      padding: 32px 34px 32px 32px;
      max-width: 100%;
    }

    @media @screen767 {
      padding: 24px 0 0 0;
    }

    .title {
      .display-h2;
      .mb-4;

      @media @screen1024 {
        .display-h3;
      }

      @media @screen767 {
        margin-bottom: 2px;
      }
    }

    .featured-collection-video-count {
      .text-small;
      .mb-4;

      @media @screen767 {
        .text-md;
        margin-bottom: 2px;
      }
    }

    p {
      .paragraph-l;

      @media @screen767 {
        .paragraph-m;
      }
    }

    button {
      margin-top: 32px;
      padding: 0 32px;

      @media @screen767 {
        margin-top: 24px;
        width: 100%;
      }
    }
  }
}

.featurecollection-slider {
  .ant-carousel {
    .slick-slider {
      .slick-dots {
        bottom: 16px;

        li {
          width: auto;

          &.slick-active {
            width: auto;
            button {
              background: @tq-neutral-0;
            }
          }

          button {
            height: 8px;
            width: 8px;
            background: @tq-neutral-500;
            margin: 0;
            border-radius: 50%;
          }
        }
      }
    }

    .slick-arrow {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background: @tq-neutral-200;
      z-index: 1;

      &.slick-next {
        right: 30px;
        background-color: var(--tq-neutral-900);
        background-image: url('/images/slick-right.svg');
        background-repeat: no-repeat;
        background-position: center;
        @media @screen1150 {
          right: 5px;
        }
      }

      &.slick-prev {
        left: 30px;
        background-color: var(--tq-neutral-900);
        background-image: url('/images/slick-left.svg');
        background-repeat: no-repeat;
        background-position: center;
        @media @screen1150 {
          left: 5px;
        }
      }
    }
  }
}
/*article-card card component css*/
.featured-video-card {
  &.article-card {
    z-index: 1;
    .comment-count {
      display: flex;
      gap: 8px;
      @media @screen767 {
        justify-content: space-between;
        padding-bottom: 8px;
        border-bottom: 1px solid var(--tq-border-light);
        margin-bottom: 8px;
      }
    }
    .fvc-details {
      @media @screen767 {
        background: transparent;
        padding: 16px 0;
      }
    }
    .fvc-details .fvc-description {
      @media @screen767 {
        margin-top: 0;
      }
    }
    .fvc-image {
      @media @screen767 {
        height: 0;
        padding-top: 56%;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        z-index: 1;
      }
      &::before {
        content: '';
        background: linear-gradient(
          180deg,
          transparent 4.55%,
          rgba(0, 0, 0, 0.85)
        );
        height: calc(100% - 86px);
        bottom: 0;
        left: 0;
        width: 100%;
        position: absolute;
        z-index: 1;
        display: block;
        @media @screen767 {
          display: none;
        }
      }
    }
    .fvc-details .fvc-description .heading {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .fvc-details .slider-button {
      max-width: 280px;
      display: flex;
      flex-direction: row;
      gap: 8px;
      @media @screen767 {
        max-width: 100%;
        flex-direction: row;
      }
      button:last-child {
        margin: 0;
      }
      button {
        font-size: 0;
        gap: 0;
        @media @screen767 {
          font-size: 16px;
          display: flex;
          align-items: center;
          flex-direction: column;
          height: auto;
          padding: 8px 0;
          gap: 4px;
        }
        @media @screen540 {
          font-size: 14px;
        }
      }
    }
  }
}
.feature-video-card-v2 {
  display: flex;
  box-shadow: 0px 4px 12px 2px #00000033;
  background: var(--tq-neutral-700);
  border-radius: 16px;
  overflow: hidden;
  @media @screen767 {
    flex-direction: column;
    border-radius: 8px;
  }
  .feature-video-v2-img {
    position: relative;
    width: 53.498%;
    min-height: 404px;
    background-color: var(--tq-neutral-1000);
    @media @screen767 {
      width: 100%;
      min-height: 150px;
      padding-top: 64.435%;
    }
  }
  .feature-video-v2-des {
    flex: 1;
    padding: 40px;
    @media @screen1150 {
      padding: 24px;
    }
    @media @screen767 {
      padding: 16px;
    }
    .tag-carousel {
      margin-top: 8px;
      a {
        display: inline-block;
        .ant-tag {
          margin-bottom: 8px;
          display: flex;
          font: var(--tq-text-tiny);
          border-color: var(--tq-neutral-500);
          color: var(--tq-white-90);
          background: var(--tq-neutral-700);
          border-spacing: 1px;
        }
      }
    }
    .heading {
      color: var(--tq-black-90);
      font: var(--tq-heading-h2);
      margin-top: 12px;
      margin-bottom: 4px;
    }
    .premium-tag.ant-badge {
      margin-left: 8px;
      top: 3px;
    }
    .date {
      color: var(--tq-black-90);
      font: var(--tq-text-tiny);
    }
    .description {
      font: var(--tq-text-tiny);
      line-height: 24px;
      color: var(--tq-black-80);
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .slider-button {
      margin-top: 32px;
      @media @screen767 {
        margin-top: 16px;
      }
      .ant-btn {
        width: 50%;
        @media @screen767 {
          width: 100%;
        }
      }
    }
  }
}
.featured-carousel .slick-slide .feature-video-card-v2 {
  opacity: 0.4;
  transform: scale(0.8682);
  -webkit-transform-origin-x: 89%;
  transition: transform 0.3s ease;
  @media @screen767 {
    opacity: 1;
    transform: scale(1);
    -webkit-transform-origin-x: 0;
  }
}
.featured-carousel .slick-slide.slick-current .feature-video-card-v2 {
  opacity: 1;
  transform: scale(1);
}
.featured-carousel
  .slick-slide.slick-current
  + .slick-slide
  .feature-video-card-v2 {
  -webkit-transform-origin-x: 10%;
  @media @screen767 {
    -webkit-transform-origin-x: 0;
  }
}
.featured-carousel {
  .feature-video-card-v2 {
    @media @screen767 {
      box-shadow: none;
    }
  }
}
