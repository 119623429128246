@import url('../variable.less');

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  text-decoration: inherit;
  vertical-align: inherit;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
s,
samp,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
em,
strong,
button,
blockquote,
small,
strike,
sub,
sup,
tt,
time,
mark,
audio,
video {
  font-family: 'Primary', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  background: @tq-neutral-1000;
  font-size: 16px;
}

body {
  &.step1 {
    @media @screen1024 {
      margin-bottom: 175px;
    }

    @media @screen767 {
      margin-bottom: 150px;
    }
  }

  &.step2 {
    @media @screen1024 {
      margin-bottom: 150px;
    }

    &.edit {
      @media @screen1024 {
        margin-bottom: 220px;
      }
    }
  }

  &.step3 {
    @media @screen1024 {
      margin-bottom: 90px;
    }
  }
}

/* HTML5 display-role reset for older browsers */

aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  scroll-behavior: smooth;
}

a {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

//=========================================
/********** margin padding gap function start here **************/
//=========================================
.for(@i, @n) {
  .-each(@i);
}

.for(@n) when (isnumber(@n)) {
  .for(1, @n);
}

.for(@i, @n) when not (@i =@n) {
  .for((@i + (@n - @i) / abs(@n - @i)), @n);
}

//--------  End of for loop  ---------//
//=========================================
//              FOR EACH LOOP             =
//=========================================
.for(@array) when (default()) {
  .for-impl_(length(@array));
}

.for-impl_(@i) when (@i > 1) {
  .for-impl_((@i - 1));
}

.for-impl_(@i) when (@i > 0) {
  .-each(extract(@array, @i));
}

//--------  End of for each loop  ---------//

@values-array: 0, 2, 6, 4, 8, 12, 16, 20, 24, 32, 40, 48;

// init functions
.make-space() {
  .for(@values-array);

  .-each(@value) {
    .gap-@{value} {
      gap: ~'@{value}px';
    }

    .p-@{value} {
      padding: ~'@{value}px';
    }

    .pt-@{value} {
      padding-top: ~'@{value}px';
    }

    .pl-@{value} {
      padding-left: ~'@{value}px';
    }

    .pr-@{value} {
      padding-right: ~'@{value}px';
    }

    .pb-@{value} {
      padding-bottom: ~'@{value}px';
    }

    .m-@{value} {
      margin: ~'@{value}px';
    }

    .mt-@{value} {
      margin-top: ~'@{value}px';
    }

    .ml-@{value} {
      margin-left: ~'@{value}px';
    }

    .mr-@{value} {
      margin-right: ~'@{value}px';
    }

    .mb-@{value} {
      margin-bottom: ~'@{value}px';
    }
  }
}

.make-space();

.m-auto {
  margin: auto;
}

//=========================================
/********** margin padding gap function end here **************/
//=========================================
//=========================================
/********** text alignment start here **************/
//=========================================
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}
.vertical-middle {
  vertical-align: middle;
}
//=========================================
/********** text alignment end here **************/
//=========================================
.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.m-hide {
  display: block;

  @media @screen767 {
    display: none;
  }
}

.d-hide {
  display: none;

  @media @screen1024 {
    display: block;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

//border style
.b-0 {
  border: none;
}

.b-1 {
  border-spacing: 1px;
}

.b-2 {
  border-spacing: 2px;
}

.b-3 {
  border-spacing: 3px;
}

.b-solid {
  border-style: solid;
}

//border style
/*position class start here*/
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.unset {
  position: unset !important;
}
.sticky {
  position: sticky !important;
}

/*position class end here*/
/*loader css start here*/
.loader {
  .d-flex;
  .align-center;
  .justify-center;
  .fixed;
  .w-full;
  .h-full;
}

/*loader css end here*/
//=========================================
/********** flex box class start here **************/
//=========================================

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.flex-shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.flex-1 {
  flex: 1;
}

//=========================================
/********** flex box class end here **************/
//=========================================
/*avatar css start here*/
.ant-avatar {
  height: 48px;
  width: 48px;
  display: inline-flex;
  .align-center;
  background: var(--tq-neutral-700);
  &.ant-avatar-sm {
    height: 40px;
    width: 40px;
  }

  &.ant-avatar-lg {
    height: 64px;
    width: 64px;

    @media @screen767 {
      height: 48px;
      width: 48px;
    }
  }

  &.avatar-xl {
    height: 96px;
    width: 96px;

    @media @screen767 {
      height: 64px;
      width: 64px;
    }
  }

  &.avatar-xxl {
    height: 128px;
    width: 128px;

    @media @screen767 {
      height: 96px;
      width: 96px;
    }
  }
}

/*avatar css end here*/
/*Accordion css start here*/
.cs-accordion {
  .ant-col {
    @media @screen1024 {
      width: 100%;
    }
  }

  .ant-collapse {
    border: 0;

    > .ant-collapse-item {
      border: 1px solid @tq-border-bottom;
      border-radius: 8px;
      background: @tq-neutral-700;
      .mb-8;
      overflow: hidden;

      > .ant-collapse-header {
        background: @tq-neutral-700;
        color: @tq-black-90;
        .ant-collapse-header-text {
          font: @tq-heading-h5;

          @media @screen1024 {
            .heading-h4;
          }

          @media @screen767 {
            font: @tq-heading-h6;
          }
        }
      }

      .ant-collapse-content {
        border: 0;

        .ant-collapse-content-box {
          padding: 0 16px 16px;
          background: @tq-neutral-700;
          color: @tq-black-80;
        }
      }
    }
  }
}

/*Accordion css end here*/
/*button css start here*/
.ant-btn-default:disabled {
  background: @tq-neutral-500;
  color: @tq-neutral-100;
  border: 1px solid @tq-neutral-900;
}

.cs-button {
  .d-flex;
  .align-center;
  .justify-center;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-wrap: wrap;
  &.between {
    .d-flex;
    justify-content: space-between;
  }

  &.ant-btn.ant-btn-lg {
    font: @tq-button-l;
    letter-spacing: 0.01em;

    @media @screen767 {
      height: 50px;
      font: @tq-button-m;
      letter-spacing: 0;
      padding: 0 24px;
    }
  }

  &.ant-btn {
    font: @tq-button-m;
    letter-spacing: 0.01em;

    @media @screen767 {
      height: 42px;
      letter-spacing: 0;
    }
  }

  &.ant-btn.ant-btn-sm {
    .button-s;
    padding: 0 16px;

    @media @screen767 {
      height: 32px;
    }
  }

  span {
    &.ant-avatar {
      flex-shrink: 0;
    }
    .d-flex;
    .align-center;
  }

  // svg {
  //   &:first-child {
  //     .mr-8;
  //   }

  //   &:last-child {
  //     .ml-8;
  //   }
  // }
}

.ant-btn.sub-modal-link-btn {
  color: @tq-neutral-900;
  text-decoration: underline;
}

.form-group {
  .ant-form-item {
    margin-bottom: 0;
    .ant-input-disabled,
    .ant-input[disabled] {
      color: @tq-neutral-700;
      background: @tq-neutral-100;
    }

    .ant-form-item-extra {
      .paragraph-s;
      .text-n-700;
    }

    .ant-form-item-label {
      padding: 0;
      line-height: 1;
      .mb-8;

      @media @screen767 {
        margin-bottom: 6px;
      }

      > label {
        .text-small-bold;
        .text-black-90;
        align-items: flex-start;

        @media @screen767 {
          .text-md-bold;
          display: inherit;
        }
      }
    }
  }
}

/*button css end here*/
/*badge css start here*/

.ant-tag {
  max-height: 30px;
  min-height: 30px;
  min-width: 30px;
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 4px 8px 4px 8px;
  gap: 4px;
  border-radius: 4px;
  border: 0;
  font-size: 14px;
  &.prefix-icon {
    padding: 6px 12px 6px 8px;
    flex-direction: row;
  }

  svg {
    transform: translateX(0);
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  &:hover {
    svg {
      transform: translateX(4px);
    }
  }
}

.tag_grid {
  .d-flex;
  flex-wrap: wrap;

  .ant-tag {
    margin-bottom: 8px;
    display: flex;
    .text-tiny;
    .b-n-500;
    .text-white-90;
    .bg-n-700;
    .b-1;
  }

  .section-header {
    width: 100%;
  }
}
.tag-carousel {
  .ant-carousel {
    .ant-tag {
      margin-bottom: 8px;
      display: flex;
      .text-tiny;
      .b-n-500;
      .text-white-90;
      .bg-n-700;
      .b-1;
    }
  }
}

.taghero {
  padding: 89px 24px 81px 24px;

  @media @screen1024 {
    padding: 68px 24px 60px 24px;
  }

  @media @screen767 {
    padding: 63px 24px;
  }

  .title {
    .display-h1;
    .text-center;

    @media @screen1024 {
      .display-h2;
    }

    @media @screen767 {
      .display-h3;
    }
  }
}

/*badge css end here*/
/*ant switch css start here*/

/*ant switch css end here*/
/*datepicker css start here*/
.ant-picker-dropdown {
  .ant-picker-date-panel {
    max-width: 326px;
    width: 100%;
  }

  .ant-picker-panel-container {
    background: @tq-neutral-200;
    border: 1px solid @tq-border-bottom;
    border-radius: 8px;
    box-shadow: @tq-shadow-s;

    .ant-picker-panel {
      padding: 0 16px;
      width: 100%;
    }
  }

  .ant-picker-header {
    padding: 0;
    border-bottom: 1px solid @tq-border-bottom;

    .ant-picker-header-next-btn,
    .ant-picker-header-prev-btn {
      color: @tq-neutral-900;
    }
  }

  .ant-picker-date-panel {
    .ant-picker-body {
      padding: 16px 0;

      .ant-picker-content {
        width: 100%;

        th {
          font: @tq-text-tiny-bold;
          color: @tq-neutral-700;
          height: 16px;

          @media @screen767 {
            font: @tq-text-small-bold;
            height: 14px;
          }
        }

        .ant-picker-cell-in-view {
          .ant-picker-cell-inner {
            font: @tq-text-small;
            min-width: 36px;
            height: 36px;
            line-height: 36px;

            @media @screen767 {
              font: @tq-text-md;
              min-width: 32px;
              height: 32px;
              line-height: 32px;
              width: 32px;
            }
          }

          &.ant-picker-cell-selected {
            .ant-picker-cell-inner {
              font: @tq-text-small;
              height: 36px;
              width: 36px;
              line-height: 36px;
              border-radius: 8px;

              @media @screen767 {
                font: @tq-text-md;
                min-width: 32px;
                height: 32px;
                line-height: 32px;
                width: 32px;
              }

              &::before {
                border-radius: 8px;
              }
            }
          }

          &.ant-picker-cell-today {
            .ant-picker-cell-inner {
              font: @tq-text-small;
              height: 36px;
              width: 36px;
              line-height: 36px;
              border-radius: 8px;

              @media @screen767 {
                font: @tq-text-md;
                min-width: 32px;
                height: 32px;
                line-height: 32px;
                width: 32px;
              }

              &::before {
                border-radius: 8px;
              }
            }
          }
        }

        .ant-picker-cell-start {
          .ant-picker-cell-inner {
            font: @tq-text-small;
            min-width: 36px;
            height: 36px;
            line-height: 36px;

            @media @screen767 {
              font: @tq-text-md;
              min-width: 32px;
              height: 32px;
              line-height: 32px;
              width: 32px;
            }
          }
        }
      }
    }
  }

  .ant-picker-header-view {
    button {
      font: @tq-text-md-bold;
      color: @tq-neutral-900;
      line-height: 52px;

      @media @screen767 {
        font: @tq-text-lg-bold;
        line-height: 52px;
      }
    }
  }

  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn {
    display: none;
  }

  .ant-picker-panel {
    .ant-picker-footer {
      border: 0;

      .ant-picker-today-btn {
        display: none;
      }

      .ant-picker-footer-extra {
        border: 0;
      }
    }
  }
}

.datepicker-footer {
  .d-flex;
  justify-content: space-between;
  margin: 0 -8px;
  padding-bottom: 16px;

  button {
    width: calc(50% - 16px);
    margin: 0 8px;
  }
}

/*datepicker css end here*/
/*ant model css start here*/
.ant-modal {
  &.share-popup {
    .ant-modal-content {
      background: @tq-neutral-700;
      border-radius: 18px;
      overflow: hidden;

      .ant-modal-header {
        background: @tq-neutral-700;
      }
    }
  }

  .ant-modal-content {
    border-radius: 0;
    background: @tq-neutral-700;

    .ant-modal-close {
      top: 28px;
      color: @tq-neutral-900;
    }

    .ant-modal-header {
      background: @tq-neutral-0;
      padding: 24px 24px 0 24px;
      border: 0;

      .ant-modal-title {
        font: @tq-heading-h4;
      }
    }

    .ant-modal-body {
      padding: 16px 24px;
    }

    .ant-modal-footer {
      border: 0;
      .pl-24;
      .pr-24;
      .pb-24;
      .pt-0;

      @media @screen540 {
        .d-flex;
      }

      .ant-btn {
        line-height: 20px;
        .pl-24;
        .pr-24;

        @media @screen540 {
          flex: 1 1;
          width: 100%;
          .d-flex;
          .align-center;
          .justify-center;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  &.fc-model {
    @media @screen540 {
      max-width: 100%;
      top: 0;
      height: 100%;
      padding: 0;
    }

    .ant-modal-content {
      @media @screen540 {
        height: 100%;
        .d-flex;
        .flex-vertical;
      }

      .ant-modal-body {
        @media @screen540 {
          flex: 1;
        }
      }
    }
  }
}

/*ant modal css end here*/
/*ant tab css start here*/
.ant-tabs {
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      margin: 0;
      padding: 10px 16px 16px 16px;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      .text-md-bold;
    }
  }

  .ant-tabs-tab-btn {
    line-height: 20px;
    color: @tq-neutral-900;
    font: @tq-text-md;
  }
}

/*ant tab css end here*/
/*tooltip css start here*/
.ant-tooltip {
  .ant-tooltip-inner {
    padding: 10px;
    font: @tq-text-small;

    @media @screen767 {
      padding: 8px;
      font: @tq-text-md;
    }
  }
}

/*tooltip css end here*/

/*****************module component css start here************************/

/*section header css start here*/
.section-header {
  margin-bottom: 16px;

  .sh-top {
    .d-flex;
    justify-content: space-between;

    .sh-title {
      .heading-h3;
      font-family: var(--tq-secondary-font);
      @media @screen1024 {
        .heading-h4;
        font-family: var(--tq-secondary-font);
      }
      @media @screen767 {
        .heading-h5;
        font-family: var(--tq-secondary-font);
      }
    }

    a {
      .text-black-90;
      .gap-4;
      svg {
        position: relative;
        transform: translateX(0);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        .text-n-900;
        svg {
          transform: translateX(4px);
        }
      }
    }
  }

  .vc-paragraph {
    .text-md;
    letter-spacing: 0.02em;
    margin-bottom: 0;
    line-height: 1.4;
    @media @screen1024 {
      .text-small;
    }
  }
}

/*section header css end here*/

/*category card css start here*/
.one-side-carousel {
  .ant-carousel {
    .slick-list {
      padding-left: 48px;

      @media @screen1150 {
        padding-left: 24px;
      }

      @media @screen767 {
        padding-left: 16px;
      }
    }
  }
}

.ant-carousel .slick-list .slick-track {
  .d-flex;
}

/*****************module component css end here************************/
fieldset {
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

/*ant drawer css start here*/
.ant-drawer {
  .cs-ant-drawer {
    border-radius: 8px 0 0 0;

    .ant-drawer-header {
      padding: 16px;
      background: @tq-neutral-400;
      border-bottom: 1px solid @tq-border-bottom;

      .ant-drawer-header-title {
        flex-direction: row-reverse;

        .ant-drawer-title {
          font: @tq-display-h4;
          letter-spacing: 0.01em;
        }

        .ant-drawer-close {
          margin: 0;
          color: @tq-neutral-1000;
        }
      }
    }

    .ant-drawer-body {
      background: @tq-neutral-200;
      padding: 0;
    }

    .ant-tabs-nav {
      background: @tq-neutral-400;
    }

    .ant-tabs-content-holder {
      padding: 0 16px;
    }
  }
}

/*ant drawer css end here*/
/*radio and checkbox css start here*/
.ant-radio-wrapper,
.ant-checkbox-wrapper {
  font: @tq-text-small;
  align-items: center;
  display: flex;

  @media @screen767 {
    font: @tq-text-md;
  }
}

/*radio and checkbox css end here*/
/*shadow arrow css start here*/
.shadow-arrow {
  .ant-carousel .slick-list .slick-slide {
    pointer-events: auto;
  }

  .ant-carousel {
    .slick-arrow {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: @tq-neutral-900;
      box-shadow: 0px 45px 18px rgba(78, 68, 54, 0.02),
        0px 25px 15px rgba(78, 68, 54, 0.08),
        0px 11px 11px rgba(78, 68, 54, 0.13), 0px 3px 6px rgba(78, 68, 54, 0.15),
        0px 0px 0px rgba(78, 68, 54, 0.15);
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(-34%);
      @media @screen767 {
        display: none !important; /*important for overwrite the slick inline css*/
      }
      &.slick-prev {
        left: 32px;
        z-index: 1;
        background-color: @tq-neutral-900;
        background-image: url('/images/slick-left.svg');
        background-repeat: no-repeat;
        background-position: center;
        @media @screen1150 {
          left: 8px;
        }
        @media @screen1024 {
          left: 10px;
        }
      }

      &.slick-next {
        right: 32px;
        background-color: @tq-neutral-900;
        background-image: url('/images/slick-right.svg');
        background-repeat: no-repeat;
        background-position: center;
        @media @screen1150 {
          right: 8px;
        }
        @media @screen1024 {
          right: 10px;
        }
      }
    }
  }
}

/*shadow arrow css end here*/

/*error  page css start here*/
.error-page-wrapper {
  min-height: calc(100vh - 148px);
  padding-top: 64px;
  text-align: left;
  position: relative;
  padding-bottom: 30px;

  @media @screen1024 {
    min-height: calc(100vh - 128px);
    padding: 40px 16px;
  }

  @media @screen767 {
    padding: 40px 0;
  }

  .cs-button.ant-btn.ant-btn-lg {
    @media @screen1024 {
      height: 50px;
    }
  }

  .error-heading {
    font-family: @tq-secondary-font;
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 77px;
    letter-spacing: 0.02em;

    @media @screen767 {
    }
  }

  .devider-error {
    width: 80px;
    height: 4px;
    background: @tq-red-2;
    margin: 40px 0;

    @media @screen1024 {
      margin: 32px 0;
    }
  }

  p {
    max-width: 1024px;
    margin-bottom: 32px;

    @media @screen767 {
      margin-bottom: 24px;
    }
  }
}

/*error page css end here*/
.ant-carousel .slick-list .slick-slide {
  pointer-events: auto;
}

.tag-carousel {
  overflow: auto;
  display: block;

  &::-webkit-scrollbar {
    height: 0px;
  }
}

.ant-badge {
  &.badge-l {
    padding: 5px 18px 7px 18px;
    border-radius: 200px;
    .text-md-bold;

    @media @screen767 {
      padding: 5px 16px 7px 16px;
    }
  }

  &.badge-md {
    padding: 4px 16px 7px 16px;
    border-radius: 200px;
    .text-small-bold;

    @media @screen767 {
      padding: 5px 12px 6px 12px;
    }
  }

  &.badge-xs {
    padding: 4px 12px 6px 12px;
    border-radius: 200px;
    .text-tiny;

    @media @screen767 {
      padding: 3px 8px 4px 8px;
    }
  }
  &.video-badge {
    background: transparent;
    border: 1px solid @tq-white-90;
    color: @tq-black-90;
  }
}

.ant-input-affix-wrapper {
  min-height: 44px;
  max-height: 44px;

  @media @screen1024 {
    min-height: 48px;
    max-height: 48px;
  }

  @media @screen767 {
    min-height: 44px;
    max-height: 44px;
  }

  .ant-input {
    min-height: 100%;
    max-height: 100%;
  }
}

.ant-input {
  min-height: 44px;
  max-height: 44px;
  padding: 12px 16px;

  @media @screen767 {
    .text-lg;
    padding: 13px 12px;
  }
}

.cs-radiogroup {
  .ant-radio-group {
    .d-flex;
    flex-wrap: wrap;
    gap: 16px;

    @media @screen767 {
      gap: 12px;
    }

    .ant-radio-button-wrapper {
      border-radius: 8px;
      text-align: center;
      min-width: 60px;
      border: 1px solid @tq-neutral-900;
      flex: 1;
      padding: 0;
      line-height: 45px;

      @media @screen1024 {
        min-width: 48px;
        height: 48px;
        line-height: 48px;
      }

      @media @screen767 {
        min-width: 40px;
        height: 40px;
        line-height: 40px;
      }

      &.ant-radio-button-wrapper-checked {
        background: @tq-neutral-900;
        color: @tq-neutral-100;
      }

      span {
        .paragraph-m;

        @media @screen1024 {
          .button-m;
        }
      }

      &::before {
        display: none;
      }
    }
  }
}

.cs-button.plan-cancel-link-btn {
  color: @tq-neutral-900;
  text-decoration: underline;
}

.form-group {
  .react-tel-input {
    .form-control {
      width: 100%;
      border-width: 1px;
      border-style: solid;
      border-color: @tq-border-dark;
      border-radius: 8px;
      height: 44px;
      padding-left: 82px;
    }

    .flag-dropdown {
      width: 66px;
      background: @tq-neutral-400;
      border-radius: 8px 0 0 8px;

      &.open {
        background: @tq-neutral-400;
        border-radius: 8px 0 0 8px;

        .country-list {
          border-radius: 8px;
        }

        .selected-flag {
          background: @tq-neutral-400;
          &.open {
            border-radius: 8px 0 0 8px;
          }
        }
      }

      .selected-flag {
        width: 100%;
        padding: 0 0 0 14px;
        border-radius: 8px 0 0 8px;

        .flag {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(1.4);
          margin: 0;
        }

        &[title=''] {
          .flag {
            background-image: url(/images/fleg-null.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1.4);
            margin: 0;
          }
        }

        .arrow {
          display: none;
        }
      }
    }
  }
}

.sitemap-links {
  list-style: none;

  li {
    .button-l;
    .mb-8;

    a {
      .text-n-900;
      .d-flex;
      .align-center;
      gap: 8px;
    }
  }
}

.fixed-btn-responsive {
  @media @screen767 {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 16px;
    background: @tq-neutral-200;
    width: 100%;
  }

  button {
    @media @screen767 {
      width: 100%;
    }
  }
}

.filterhide {
  .filter-block {
    display: none;

    @media @screen1024 {
      .d-flex;
    }
  }
}

.filter-block {
  background: var(--tq-neutral-1000);
  padding: 24px;
  box-shadow: 0px 19px 8px rgba(78, 68, 54, 0.02),
    0px 5px 7px rgba(78, 68, 54, 0.13);
  border-radius: 8px;

  @media @screen1024 {
    height: 100%;
    box-shadow: unset;
    padding: 0;
    .d-flex;
    .flex-vertical;
    justify-content: space-between;
  }

  .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background: @tq-neutral-1000;
  }

  .ant-checkbox .ant-checkbox-inner,
  .ant-radio-wrapper .ant-radio-inner {
    background: transparent;
  }

  .filter-body {
    .mb-16;

    @media @screen1024 {
      flex: 1;
      overflow: auto;
      padding: 12px 24px;
      margin: 0;
    }
  }

  .filter-item {
    .filter-title {
      .heading-h6;
      .mb-8;
      .text-black-90;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid @tq-neutral-500;
      padding-bottom: 12px;
      margin-bottom: 12px;
    }
  }

  .filter-button-group {
    .d-flex;
    gap: 12px;

    @media @screen1024 {
      padding: 24px;
      background: @tq-ivory;
    }

    button {
      flex: 1;
      padding: 0;
    }
  }
}

.filter-checkbox-group {
  .ant-checkbox-group {
    .flex-vertical;
    gap: 8px;

    .ant-checkbox-wrapper {
      margin: 0;
    }
  }
}

.search-filter {
  .d-flex;
  gap: 12px;

  .auto-complete-search {
    flex: 1;

    .ant-btn {
      height: 44px;
      padding: 0;

      &:hover {
        background: @tq-black-80;
      }
    }
  }

  @media @screen1024 {
    gap: 8px;
  }

  input {
    flex: 1;
  }

  button {
    height: 44px;
    width: 44px;
    min-width: 44px;
    border-radius: 50%;
    padding: 0;
  }
}

/*steps css start here*/
.cs-steps {
  &.ant-steps {
    counter-reset: topx;

    &.ant-steps-vertical {
      flex-direction: row;
    }

    .ant-steps-item {
      flex: unset;

      &.ant-steps-item-finish {
        .ant-steps-item-container {
          .ant-steps-item-title {
            .text-small;
            .text-n-700;
          }
        }

        .ant-steps-icon {
          &::before {
            content: counter(topx);
            counter-increment: topx;
          }

          span {
            display: none;
          }
        }

        .ant-steps-item-container .ant-steps-item-icon {
          background: @tq-neutral-500;
        }
      }
    }

    .ant-steps-item-container {
      .d-flex;
      .align-center;
    }

    .ant-steps-item-wait {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          background: @tq-neutral-500;

          .ant-steps-icon {
            .text-n-1000;
          }
        }

        .ant-steps-item-title {
          .text-small;
          padding-inline-end: 8px;
        }
      }
    }

    .ant-steps-item-container {
      .ant-steps-item-tail {
        display: none;
      }

      .ant-steps-item-content {
        min-height: 0;
      }

      .ant-steps-item-icon {
        width: 28px;
        height: 28px;
        .label-l;
        line-height: 26px;
        background: @tq-red-2;
        border: 0;
        margin-inline-end: 12px;
        .d-flex;
        .align-center;
        .justify-center;
        .flex-shrink-0;

        @media @screen767 {
          width: 20px;
          height: 20px;
          .text-small;
          margin-inline-end: 4px;
        }

        svg {
          fill: #fff;
        }
      }

      .ant-steps-item-title {
        .text-md-bold;
        padding-inline-end: 8px;

        @media @screen767 {
          .text-small-bold;
          white-space: nowrap;
        }

        &::after {
          display: none;
        }
      }
    }
  }
}

/*steps css end here*/
.ant-select {
  outline: none;
}

.section-page {
  padding: 24px 0 72px 0;

  @media @screen767 {
    padding: 16px 0 48px 0;
  }
}

.search-page {
  padding-top: 32px;

  .container {
    @media @screen767 {
      padding: 0;
    }
  }

  .search-filter {
    max-width: 589px;

    @media @screen1024 {
      max-width: 100%;
    }

    @media @screen767 {
      padding: 0 16px;
    }
  }

  .search-grid {
    .d-flex;

    .filter-block {
      min-width: 300px;
      width: 100%;
    }

    .container {
      padding: 0;
    }
  }

  .video-grid {
    margin-left: 40px;
    width: 100%;

    @media @screen1024 {
      margin-left: 0;
    }
  }
}

body.filterhide {
  .search-page {
    .video-grid {
      .ml-0;
    }
  }
}

.FilterTagList {
  .d-flex;
  .flex-shrink-0;
  overflow: auto;
  gap: 8px;

  button {
    &.cs-button {
      border-radius: 100px;
      height: 33px;
      padding: 6px 16px 9px 16px;
      .text-small-bold;

      @media @screen767 {
        height: 30px;
        .text-md-bold;
      }
    }
  }
}

.taglanding-page {
  .section-page {
    padding-top: 32px;

    @media @screen767 {
      padding-top: 24px;
    }
  }

  .section-header .sh-top .sh-title {
    margin-bottom: 8px;

    @media @screen767 {
      margin-bottom: 0;
    }
  }
}

.category-landing-page {
  padding-bottom: 72px;

  @media @screen767 {
    padding-bottom: 48px;
  }

  .video-list-grid .section-header {
    padding-bottom: 12px;

    @media @screen767 {
      margin: 0;
    }
  }

  .video-card.video-list .video-image {
    width: 32.271%;
    padding-top: 18.128%;

    @media @screen767 {
      width: 34.989%;
      padding-top: 19.826%;
    }
  }

  .featured-video-card {
    padding: 40px 0;

    @media @screen767 {
      padding: 0 0 32px 0;
    }
  }

  .video-carousel-section {
    .section-header {
      margin-bottom: 16px;

      .sh-title {
        .heading-h4;

        @media @screen767 {
          .heading-h6;
        }
      }

      .sh-top {
        .align-start;

        a {
          .text-md-bold;
        }
      }
    }
  }

  .featured-collection {
    padding: 40px 0;

    @media @screen767 {
      padding: 32px 0;
    }
  }

  .video-list-filter-grid {
    .d-flex;
    .align-start;
    gap: 40px;

    .filter-block {
      max-width: 300px;
      width: 100%;
    }

    .video-list-filter-grid-right {
      .search-filter {
        max-width: 592px;
        margin-bottom: 12px;
      }

      .container,
      .container-rnp {
        padding: 0;
      }
    }
  }
}

.video-list-filter-section {
  .container {
    @media @screen767 {
      padding: 0;
    }
  }

  .section-header {
    @media @screen767 {
      padding: 0 16px 12px 16px;
    }
  }

  .search-filter {
    @media @screen767 {
      padding: 0 16px 0 16px;
      margin-bottom: 16px;
    }
  }
}

.collectionlanding-page {
  &.search-page {
    padding-top: 0;

    .search-filter {
      @media @screen767 {
        padding: 0;
      }
    }

    .container {
      @media @screen767 {
        padding: 0 16px;
      }

      .container {
        @media @screen767 {
          padding: 0;
        }
      }
    }
  }

  .section-page {
    padding-top: 32px;

    @media @screen767 {
      padding-top: 24px;
    }
  }

  .FilterTagList {
    margin: 16px 0 8px 0;

    @media @screen767 {
      margin-bottom: 0;
    }
  }
}

.Video-detail-page {
  padding-bottom: 72px;

  @media @screen767 {
    padding-bottom: 0;
  }

  .video-info-wrapper {
    padding-top: 24px;

    @media @screen767 {
      padding-bottom: 24px;
    }
  }

  .s-40 {
    @media @screen767 {
      padding-bottom: 32px;
    }
  }
}

.table-invite-grid {
  &:last-child {
    @media @screen1024 {
      gap: 24px;
      background: @tq-neutral-200;
      border-radius: 16px;
      padding: 16px;
      box-shadow: 0px 19px 8px rgba(78, 68, 54, 0.02),
        0px 5px 7px rgba(78, 68, 54, 0.13);
      margin: 0 24px;
    }

    @media @screen767 {
      background: transparent;
      box-shadow: none;
      margin: 0;
    }
  }
}

.table-invite-page {
  padding: 32px 0 75px 0;

  @media @screen767 {
    padding: 24px 0 48px 0;
  }

  .table-invite-grid {
    .d-flex;
    gap: 40px;

    @media @screen1150 {
      gap: 20px;
    }

    @media @screen767 {
      flex-direction: column-reverse;
      padding: 0 16px;
    }

    &:first-child {
      @media @screen1024 {
        .flex-vertical;
        gap: 44px;
        padding: 0;
      }

      .table-invite-left {
        @media @screen1024 {
          width: 100%;
          max-width: 524px;
          margin: auto;
        }

        @media @screen767 {
          padding: 0 16px 0 16px;
        }
      }
    }

    .table-invite-left {
      width: 31.25%;

      @media @screen1024 {
        width: 100%;
      }

      .tableCard {
        max-width: 100%;
      }

      .image-tbl {
        position: relative;
        padding-top: 58.098%;
        border-radius: 16px;
        overflow: hidden;
      }
    }

    .table-invite-right {
      width: 65.774%;

      @media @screen1024 {
        width: 100%;
      }

      .content {
        h3 {
          .display-h3;
          .mb-4;

          @media @screen1024 {
            .display-h4;
          }
        }

        p {
          .paragraph-l;
        }
      }
    }
  }

  .s-40 {
    @media @screen767 {
      padding-bottom: 32px;
    }
  }
}

.cs-devider {
  .ant-divider-with-text {
    border-block-start: 0 @tq-border-dark;
    margin: 24px 0;

    @media @screen767 {
      margin: 20px 0;
    }

    .ant-divider-inner-text {
      .label-l;
      .text-black-70;
      text-transform: uppercase;

      @media @screen767 {
        .label-xl;
      }
    }
  }
}

.all-filter-tags {
  .FilterTagList {
    flex-wrap: wrap;
  }
}

.video-list-page {
  .video-list-grid {
    .section-header {
      @media @screen767 {
        padding: 0 16px 16px 16px;
      }
    }

    .container {
      @media @screen767 {
        padding: 0;
      }
    }
  }
}

.home-page {
  padding: 24px 0 72px 0;

  @media @screen767 {
    padding: 0 0 48px 0;
  }

  .featured-carousel,
  .video-carousel,
  .category-slider,
  .featured-collection,
  .collection-carousel {
    margin-bottom: 40px;

    @media @screen767 {
      margin-bottom: 32px;
    }
  }

  .section-header .sh-top .sh-title {
    .heading-h4;

    @media @screen767 {
      .heading-h6;
    }
  }

  .s-40 {
    @media @screen767 {
      padding-bottom: 32px;
    }
  }
}

.cms-pages {
  padding: 96px 0;
  .text-black-90;
  @media @screen767 {
    padding: 32px 0;
  }

  .container {
    max-width: 1180px;
  }

  h1 {
    margin-bottom: 40px;

    @media @screen767 {
      margin-bottom: 30px;
    }
  }

  h2 {
    margin: 30px 0;
    scroll-margin-top: 100px;

    @media @screen767 {
      margin: 20px 0;
      .heading-h2;
      scroll-margin-top: 80px;
    }
  }

  strong {
    .mb-16;
    display: inline-block;
  }

  .disc {
    list-style: disc;
    .text-lg;
    .mb-16;
    .text-black-90;
    margin-left: 60px;

    @media @screen767 {
      margin-left: 30px;
    }
  }

  p {
    .text-lg;
    .mb-16;
    .text-black-90;
  }

  a {
    word-break: break-all;
    .text-n-900;
  }

  h3 {
    .heading-h3;
    margin: 16px 0;
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 8px;

      a {
        text-decoration: underline;
        .label-l;
        .text-black-90;
      }
    }
  }
}

.cookie-modal {
  vertical-align: bottom !important;
  margin-bottom: 30px !important;
  &.ant-modal {
    .ant-modal-content {
      padding: 12px;
      border-radius: 8px;
      .text-black-90;
      .bg-n-700;
    }

    .ant-modal-body {
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 22px;
      line-height: 22px;

      .cookie-modal--title {
        display: flex;
        align-items: center;

        :first-child {
          margin-right: 10px;
          font-size: 30px;
          min-width: 30px;
        }
      }

      .ant-btn {
        // border: 0 !important;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 100%;
        max-width: 82px;
        width: 100%;
        margin-left: 10px;
        padding: 12px 20px;
      }
    }
    @media (max-width: 500px) {
      .ant-modal-body {
        flex-direction: column;
        font-size: 20px !important;
        line-height: 20px !important;
      }

      .ant-btn {
        margin-left: 0px !important;
        margin-top: 10px !important;
        padding: 10px 19px;
        font-size: 16px;
      }
    }
  }
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled):hover,
.ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: @tq-neutral-900;
  color: @tq-black-90;
}

.ant-select-dropdown:hover .ant-select-item-option-content {
  background: transparent;
}

.ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled):hover {
  background: @tq-neutral-700;
}

.custom-control-wrap {
  .ant-select .ant-select-arrow .anticon > svg {
    @media (max-width: 767px) {
      width: 16px;
      height: auto;
    }
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    @media (max-width: 767px) {
      font-size: 14px;

      .ant-select-dropdown:hover .ant-select-item-option-content {
        font-size: 14px;
      }
    }
  }
}

.ant-menu-submenu > .ant-menu .ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu > .ant-menu .ant-menu-item img {
  mix-blend-mode: difference;
}

.ant-menu-submenu > .ant-menu .ant-menu-item a {
  display: flex;
  align-items: center;
  line-height: 40px;
}

.social-share-icon {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;

  button {
    height: 48px;

    @media (max-width: 767px) {
      height: 40px;
    }

    svg {
      @media (max-width: 767px) {
        width: 40px;
        height: auto;
      }
    }
  }
}

.ipl-progress-indicator {
  width: 100%;
  position: fixed;
  opacity: 1;
  pointer-events: none;
  -webkit-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  -moz-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
  z-index: 9999;
}

.insp-logo-frame {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-animation: fadein 436ms;
  -moz-animation: fadein 436ms;
  animation: fadein 436ms;
  height: 98%;
}

.insp-logo-frame-img {
  width: 112px;
  height: 112px;
  -webkit-align-self: center;
  -moz-align-self: center;
  align-self: center;
  border-radius: 50%;
}

.ipl-progress-indicator-head {
  background-color: transparent;
  height: 3px;
  overflow: hidden;
  position: relative;
}

.ipl-progress-indicator .first-indicator,
.ipl-progress-indicator .second-indicator {
  background-color: @tq-red-2;
  /* define progress color here */
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
}

.ipl-progress-indicator .first-indicator {
  -webkit-animation: first-indicator 1.5s linear infinite;
  -moz-animation: first-indicator 1.5s linear infinite;
  animation: first-indicator 1.5s linear infinite;
}

.ipl-progress-indicator .second-indicator {
  -webkit-animation: second-indicator 1.5s linear infinite;
  -moz-animation: second-indicator 1.5s linear infinite;
  animation: second-indicator 1.5s linear infinite;
}

@keyframes first-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }

  25% {
    transform: translate(0) scaleX(0.5);
  }

  50% {
    transform: translate(25%) scaleX(0.75);
  }

  75% {
    transform: translate(100%) scaleX(0);
  }

  100% {
    transform: translate(100%) scaleX(0);
  }
}

@keyframes second-indicator {
  0% {
    transform: translate(0) scaleX(0);
  }

  60% {
    transform: translate(0) scaleX(0);
  }

  80% {
    transform: translate(0) scaleX(0.6);
  }

  100% {
    transform: translate(100%) scaleX(0.1);
  }
}

.share-popup {
  &.ant-modal {
    max-width: 448px;
    width: 94% !important;
    overflow: hidden;
    .ant-modal-content {
      border-radius: 8px;
      box-shadow: 0px 19px 8px rgba(78, 68, 54, 0.02),
        0px 5px 7px rgba(78, 68, 54, 0.13);
    }
  }

  &.ant-modal .ant-modal-content .ant-modal-header .ant-modal-title {
    .heading-h5;
    .text-black-90;
  }

  &.ant-modal .ant-modal-content .ant-modal-header {
    padding: 16px 24px 0 24px;
  }

  &.ant-modal .ant-modal-content .ant-modal-close {
    top: 16px;
    right: 24px;
    .text-black-90;
    height: 24px;
    width: 24px;
  }

  &.ant-modal .ant-modal-content .ant-modal-body {
    padding: 24px 24px 20px 24px;
  }
}

.video-selected {
  .ant-empty-image {
    height: 56px;
    width: 56px;
    margin: auto;

    @media @screen767 {
      height: 48px;
      width: 48px;
    }
  }

  .ant-empty-description {
    .text-md-bold;
  }
}

.start-content-block {
  .ant-empty-image {
    height: 64px;
    width: 64px;

    margin: auto;

    @media @screen767 {
      height: 48px;
      width: 48px;
    }
  }

  .ant-empty-description {
    .text-lg-bold;
  }
}

.ant-notification-notice {
  padding: 16px;
  background: @tq-white-90;
  box-shadow: 0px 19px 8px rgba(78, 68, 54, 0.02),
    0px 5px 7px rgba(78, 68, 54, 0.13);
  border-radius: 8px;

  .ant-notification-notice-content {
    display: flex;
    align-items: center;
    .ant-notification-notice-icon {
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
    }
    .ant-notification-notice-message {
      .text-md-bold;
      margin-bottom: 0 !important;
      padding: 0;
    }
  }
}

.cta-modal {
  &.ant-modal {
    padding-bottom: 0;

    .ant-modal-content {
      background: transparent;

      .ant-modal-body {
        padding: 32px 24px;
      }
    }
  }
}

.video-dropdown {
  position: relative;
  margin-left: 10px;

  .video-setting-icon {
    position: absolute;
    left: 0;
    right: 0;
    height: 32px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    @media @screen767 {
      height: 24px;
      width: 24px;
    }

    svg {
      margin: 0;
    }
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 32px;
    width: 32px;
    padding: 0;

    @media @screen767 {
      height: 24px;
      width: 24px;
    }
  }

  .ant-select-dropdown .ant-select-item-option,
  .ant-select-dropdown .rc-virtual-list .ant-select-item-option {
    margin: 5px;
    min-height: 32px;
    padding: 7px 8px 9px 8px;
  }

  .dropdown-header {
    padding: 8px 12px;
    border-bottom: 1px solid #ddd3c5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: @tq-white-90;
    svg {
      line {
        stroke: @tq-white-90;
      }
    }
  }
  .ant-select-dropdown .rc-virtual-list .ant-select-item-option {
    color: @tq-black-90;
  }
  .ant-select-selection-item {
    opacity: 0;
  }

  .ant-select-dropdown {
    min-width: 136px !important;
    padding: 0;
  }

  .ant-select-dropdown .ant-select-item-option-content {
    .text-tiny;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: @tq-neutral-900;
    color: @tq-neutral-100;
    padding: 7px 8px 9px 8px;
    border-radius: 4px;
  }

  .ant-select {
    &.ant-select-open {
      .ant-select-arrow + div {
        &::before {
          @media @screen540 {
            visibility: visible;
          }
        }
      }
    }

    .ant-select-arrow + div {
      @media @screen540 {
        position: fixed !important;
        bottom: 0;
        left: 0;
        width: 100%;
        top: unset !important;
        z-index: 10;
      }

      &::before {
        @media @screen540 {
          content: '';
          position: fixed;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          background: @tq-neutral-1000;
          opacity: 0.6;
          visibility: hidden;
        }
      }

      .ant-select-dropdown {
        @media @screen540 {
          position: fixed !important;
          bottom: 0 !important;
          left: 0 !important;
          top: unset !important;
          min-width: 100% !important;
          border-radius: 0;
          background: @tq-neutral-200;
          border-radius: 8px 8px 0px 0px;
        }

        .ant-select-item-option {
          @media @screen540 {
            margin: 0;
          }

          .ant-select-item-option-content {
            @media @screen540 {
              .text-lg;
            }
          }
        }

        .dropdown-header {
          @media @screen540 {
            padding: 14px 16px;
            .bg-n-400;
            .heading-h5;
            display: flex;
            justify-content: space-between;
          }

          svg {
            display: none;

            @media @screen540 {
              display: block;
            }
          }
        }

        .rc-virtual-list {
          @media @screen540 {
            padding: 16px;
          }
        }
      }
    }
  }
}

.overlay-outside {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.note-steps {
  display: flex;
  align-items: center;
  gap: 8px;
  font: var(--tq-text-md);
  border-radius: 8px;
  padding: 10px 0;
  margin: 10px 0;
}

.ant-notification-notice-with-icon {
  @media @screen540 {
    display: flex;
    align-items: center;
  }
}

.resources-modal {
  &.ant-modal {
    box-shadow: 0px 19px 8px rgba(78, 68, 54, 0.02),
      0px 5px 7px rgba(78, 68, 54, 0.13);
    border-radius: 8px;
    overflow: hidden;
    padding: 0;

    .resource-header {
      display: flex;
      justify-content: space-between;
      padding: 18px 16px 13px 16px;
      .bg-n-400;
      border-bottom: 1px solid @tq-border-bottom;

      @media @screen767 {
        padding: 16px;
      }

      h5 {
        @media @screen767 {
          .display-h4;
        }
      }
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      width: 100%;
    }

    .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs .ant-tabs-tabpane {
      padding: 16px;
      min-height: 390px;
      max-height: 390px;
      overflow: auto;

      @media @screen767 {
        min-height: 366px;
        max-height: 366px;
      }
    }

    .ant-tabs .ant-tabs-nav-list .ant-tabs-tab {
      flex: 1;
      justify-content: center;
      max-width: 25%;
    }

    .ant-tabs .ant-tabs-tab-btn {
      transition: none;
    }

    .ant-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
      .text-small-bold;

      @media @screen767 {
        .text-md-bold;
      }
    }

    .ant-tabs-nav-wrap {
      .bg-n-400;
    }

    .ant-modal-body {
      padding: 0;
      .bg-n-200;
    }
  }
}

.tap-to-unmute {
  cursor: pointer;
  position: absolute;
  left: 13px;
  top: 13px;
  background: #faf4e8;
  .label-xs;
  z-index: 9;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  gap: 8px;
}
.ant-select-dropdown
  .rc-virtual-list
  .rc-virtual-list-holder-inner
  .ant-select-item.ant-select-item-option {
  color: var(--tq-white-90);
  &:hover {
    color: var(--tq-black-90);
  }
}
.contain {
  object-fit: contain;
}
.cover {
  object-fit: cover;
}
.object-center {
  object-position: center;
}
.video-progress {
  position: absolute;
  bottom: 0 !important;
  left: 0;
  top: unset !important;
  background: @tq-white-70;
  height: 3px;
  width: 100%;
  z-index: 20;
  .video-load {
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    background: @tq-white-90;
  }
  .video-played {
    width: 30%;
    height: 100%;
    position: absolute;
    left: 0;
    background: @tq-red-2;
  }
}

.cms-content {
  font-size: 14px;
  & * {
    white-space: pre-line;
  }
  h1 {
    font: @tq-heading-h1;
  }
  h2 {
    font: @tq-heading-h2;
  }
  h3 {
    font: @tq-heading-h3;
  }
  h4 {
    font: @tq-heading-h4;
  }
  ul,
  ol {
    padding-left: 40px;
    margin: 24px 0;
    li {
      margin: 8px 0;
    }
  }
  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.32px;
    margin-bottom: 8px;
    @media @screen767 {
      font-size: 14px;
    }
  }
}

.cta-btn-bg-black {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: @tq-neutral-900;
    opacity: 0.28;
    height: 100%;
    z-index: 1;
    width: 100%;
  }
  &:hover {
    .topic {
      color: @tq-neutral-0;
      z-index: 2;
    }
  }
}

.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mx-auto {
  .ml-auto;
  .mr-auto;
}

.btn-link {
  font: var(--tq-text-tiny);
  color: var(--tq-neutral-900);
  margin-top: 2px;
  text-decoration: underline;
  font-weight: 500;
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
    cursor: auto;
  }
}

.custom-modal.ant-modal {
  border-radius: 24px;
  overflow: hidden;

  .ant-modal-content {
    .ant-modal-body {
      padding: 24px;

      .header {
        .info {
          font-size: 18px;
          line-height: 23px;
          text-align: center;

          & .strong {
            font-weight: 500;
          }
        }
      }
    }
    .ant-modal-footer {
      padding: 0;
    }
  }
}

.ant-select-selection-search-input {
  font-size: 20px;
  line-height: 1.5;
  font-family: Primary;
}

.subscription-plans-container {
  // padding: 48px;

  .subscription-plans {
    max-width: 1080px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .user-info {
      text-align: center;

      .user-info-description {
        max-width: 516px;
        margin: 0 auto;
      }
    }

    .plans-container {
      display: flex;
      gap: 24px;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      .subscription-plan {
        background-color: @tq-neutral-700;
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px;
        border-radius: 24px;
        box-shadow: 0 8px 60px rgba(0, 0, 0, 0.16);
        max-width: 326px;
        width: 100%;
        position: relative;

        &.active {
          border: 3px solid @tq-neutral-900;
        }

        &.active .current-plan-tag {
          background-color: @tq-neutral-900;
          display: inline-block;
          position: absolute;
          top: 0;
          left: 50%;
          color: @tq-white-90;
          padding: 10px 32px;
          border-radius: 8px;
          white-space: nowrap;
          transform: translate(-50%, -50%);
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }
    }
  }
}

.circle {
  height: 104px;
  width: 104px;
  border-radius: 50%;
  position: relative;

  .mask-luminance {
    mask-type: luminance;
  }
}

.circle:before,
.circle:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid @tq-neutral-1000;
  border-radius: 50%;
}

.circle:before {
  animation: ripple 2s linear infinite;
}

.circle:after {
  animation: ripple 2s linear 1s infinite;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

.w-max-content {
  width: max-content;
}

.custom-input {
  min-height: 44px;
  max-height: 44px;
  padding: 12px 16px;
  box-sizing: border-box;
  margin: 0;
  font-size: 20px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: Primary;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: var(--tq-neutral-100);
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--tq-border-dark);
  border-radius: 8px;
  transition: all 0.2s;

  &:focus,
  &:focus-within {
    border-color: var(--tq-neutral-900);
    box-shadow: 0 0 0 0px 0;
    outline: 0;
  }

  &[aria-invalid='true'] {
    border-color: #ff4d4f;
  }
}

.card-number-input {
  position: relative;

  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%) scale(1.2);
  }
}

.video-list {
  button.ant-btn.save-button:active {
    color: var(--tq-neutral-900);
    background: var(--tq-ivory);
  }
}

.podcast-segmented.podcast-segmented {
  color: #fff;
  background-color: #fd5704;
  .ant-segmented-item-selected {
    background-color: #f9eede;
  }
}
.manage-plan {
  .manage-plan-container {
    max-width: 1002px;
    padding: 0 16px;
    margin: auto;
    width: 100%;
    @media @screen1024 {
      padding: 0;
    }
    .manage-plan-switch {
      h4 {
        padding-top: 5px;
        @media @screen767 {
          padding-top: 2px;
        }
      }
    }
    .ant-switch {
      outline: 1px solid var(--tq-neutral-900);
    }
    .ant-switch:hover:not(.ant-switch-disabled) {
      background: transparent;
    }
    .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
      background: var(--tq-neutral-900);
    }
  }
  .manage-plan-header {
    text-align: center;
  }
  .plan-wrapper-block {
    margin: 16px 0 32px 0;
    @media @screen767 {
      margin: 10px 0 6px 0;
    }
  }
  .plan-wrapper {
    margin: -10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media @screen767 {
      flex-direction: column;
      max-width: 100%;
      width: 100%;
      margin: 0;
    }
    .item {
      background: @tq-neutral-1000;
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      width: calc(33.33% - 20px);
      margin: 10px;
      @media @screen1024 {
        width: calc(50% - 20px);
      }
      @media @screen767 {
        width: 100%;
        max-width: 358px;
        margin: 6px auto;
      }
      .plan-footer-btn {
        padding: 16px;
        .ant-btn.ant-btn-primary:disabled {
          background: @tq-white-70;
          color: @tq-neutral-600;
          border: 0;
        }
      }
      .plan-content {
        padding: 16px;
        margin-top: -60px;
        position: relative;
        flex: 1;
        .plan-input {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 25px;
          margin: 8px 0;
          span {
            font-size: 16px;
          }
          input {
            text-align: right;
            font-size: 16px;
            font-weight: 600;
            background: #fff;
            width: 130px;
          }
        }
        .plan-content-heading {
          font-size: 24px;
          line-height: 32px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 8px;
        }
        .plan-price {
          font-size: 24px;
          font-weight: 700;
          span {
            font-size: 16px;
            margin-left: 16px;
            font-weight: 400;
            color: var(--tq-white-70);
          }
        }
        .plan-discount {
          font-size: 16px;
          font-weight: 400;
          color: var(--tq-white-70);
          margin: 8px 0;
          strong {
            color: var(--tq-white-90);
            margin-right: 4px;
          }
          .ant-form-item {
            margin: 0;
          }
          .ant-input-affix-wrapper {
            background: #fff;
            font-weight: 600;
            max-width: 131px;
            min-height: 48px;
            input {
              font-weight: 600;
            }
          }
        }
        .allow-custom-amount {
          display: flex;
          align-items: center;
          justify-content: space-between;
          input {
            max-width: 131px;
            background: #fff;
            font-weight: 500;
          }
        }
        ul {
          list-style: none;
          li {
            display: flex;
            gap: 8px;
            margin-bottom: 4px;
            font-size: 16px;
            line-height: 24px;
            svg {
              flex-shrink: 0;
            }
          }
        }
      }
      .plan-header-img {
        padding-top: 70.97%;
        position: relative;
        z-index: 0;
        &::before {
          content: '';
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          position: absolute;
          background: linear-gradient(
            0deg,
            #000000 0%,
            rgba(0, 0, 0, 0) 48.27%
          );
          z-index: 1;
        }
        img {
          object-fit: cover;
        }
        .bg-images {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 45px;
          object-fit: contain;
        }
      }
    }
  }
}
.stripe-button {
  border-radius: 6px;
  overflow: hidden;
}

.stripe-input {
  box-sizing: border-box;
  margin: 0;
  min-height: 44px;
  max-height: 44px;
  padding: 12px 16px;
  color: var(--tq-neutral-700);
  font-size: 20px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: Primary;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: var(--tq-border-dark);
  border-radius: 8px;
  transition: all 0.2s;

  &.StripeElement--focus {
    border-color: var(--body-color);
    box-shadow: 0 0 0 2px rgba(211, 181, 162, 0.32);
    outline: 0;
  }

  &.StripeElement--invalid {
    border-color: #ffa39e;
    box-shadow: none;
  }
}

.card-elements {
  .form-group .ant-form-item .ant-form-item-label > label,
  .display-h5,
  .ant-space-item .heading-h5 {
    color: #111111;
  }
}

.ask-question-modal {
  &.ant-modal .ant-modal-content .ant-modal-header {
    background: transparent;
    .ant-modal-title {
      .text-black-80;
      width: 90%;
    }
  }
  textarea.ant-input {
    max-height: 100%;
  }
  .ant-modal-close-x {
    .text-white-90;
  }
}
.one-side-carousel .ant-carousel .slick-slider .slick-list .slick-track {
  display: flex;
}
.ant-btn {
  &.ant-btn-default {
    &.cs-button {
      &:not(:disabled):not(.ant-btn-disabled):hover {
        background: var(--tq-neutral-800);
        color: var(--tq-white-90);
      }
    }
  }
}

.skeleton {
  background-color: var(--tq-neutral-700);
  // The shine that's going to move across the skeleton:
  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0),
    rgba(#fff, 0.2),
    rgba(#fff, 0)
  );
  background-size: 40px 100%; // width of the shine
  background-repeat: no-repeat; // No need to repeat the shine effect
  background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
  animation: shine 1s ease infinite; // increase animation time to see effect in 'slow-mo'
  border-radius: 6px;
}

@keyframes shine {
  to {
    // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
    background-position: right -40px top 0;
  }
}

.price-skeleton {
  height: 30px;
  background-color: var(--tq-neutral-600);
  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0),
    rgba(#fff, 0.2),
    rgba(#fff, 0)
  );
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
}

.premium-tag.ant-badge {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  line-height: 1;
  border-radius: 4px;
  padding: 4px;
  color: #fff;
  span {
    font-size: 12px;
    letter-spacing: 0.5px;
  }
}

.call-back-wrapper {
  height: 100%;
  margin: auto;
  padding: 80px 0;
  @media @screen767 {
    padding: 60px 0;
  }
  .call-back-block {
    max-width: 736px;
    width: 100%;
    text-align: center;
    margin: auto;
    h2 {
      margin-bottom: 30px;
    }
    p {
      font: var(--tq-paragraph-l);
      letter-spacing: 0.02em;
      color: var(--tq-black-70);
      @media @screen767 {
        font: var(--tq-paragraph-m);
      }
    }
    .button-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px;
      margin-top: 32px;
      @media @screen767 {
        gap: 16px;
      }
    }
  }
}
.ant-list.payment-history .ant-list-item {
  color: #fff;
}

.payment-history {
  .payment-history-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .date-list-item {
      min-width: 70%;
    }
  }
  .ant-divider-horizontal {
    margin: 6px 0;
    border-block-start: 1px solid #fff;
  }
}
.discount-label {
  text-align: center;
  margin-top: 12px;
  .discount-tag-wrapper {
    display: inline-block;
    position: relative;
    padding: 2px 4px;
    .strike {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      transform: translateY(-50%) rotate(346deg);
      height: 2px;
      background: #000000;
      opacity: 0.7;
    }
    svg {
      position: absolute;
      left: -22px;
      top: 26%;
      transform: translateY(-50%);
    }
    .ant-tag {
      display: inline-flex;
      background: #ff55001a;
      border: 1px solid #ff55004d;
      margin: 0;
      font-size: 12px;
      line-height: 24px;
      font-weight: 600;
      color: var(--tq-neutral-900);
      max-height: 24px;
      min-height: 24px;
      border-radius: 24px;
      width: 80px;
      text-align: center;
    }
  }
}

.banner-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
}

.preview-indicator {
  &-box {
    display: flex;
    align-items: center;
    background: white;
    color: #000000;
    padding: 12px;
    border-radius: 8px;
    border: 1px;
    height: 66px;
    width: fit-content;
    position: absolute;
    bottom: 90px;
    left: 46px;
    z-index: 111;
    @media @screen767 {
      position: unset;
      width: 100%;
    }
    &.w-full {
      width: 100%;
    }
    .ant-progress .ant-progress-circle-trail {
      stroke: var(--tq-neutral-900);
      opacity: 0.2;
    }
    .ant-btn {
      height: 32px;
      font-size: 12px;
      padding: 8px 12px;
      line-height: 12px;
    }
  }
  &-content {
    font-size: 14px;
    margin: 0px 10px;
    width: 170px;
    @media @screen767 {
      width: 100%;
    }
    &.w-full {
      width: 100%;
    }
    &-title {
      font-weight: 700;
    }
    &-subline {
      font-size: 12px;
    }
  }
}
