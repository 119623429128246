.form-card-slider {
  width: 100%;
  display: block;
  .one-side-carousel {
    width: 100%;
    .slick-slide {
      margin: 0 8px;
      @media @screen375 {
        margin: 0 4px;
      }
    }
  }
}
.form-card {
  max-width: 360px;
  min-width: 300px;
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  @media @screen375 {
    max-width: 280px;
    min-width: 280px;
  }
  .fc-title {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-bottom: 8px;
  }
  .form-card-button-wrapper {
    margin-top: 16px;
    border-top: 1px solid var(--tq-black-70);
    display: flex;
    gap: 8px;
    padding-top: 8px;
  }
  .ant-btn.ant-btn-disabled {
    background: #a7a7a7;
    color: #5b5b5b;
  }
}

.textarea {
  max-height: none;
}

.form-title {
  .display-h4;
}

.form-btns {
  .ant-btn {
    width: 48%;
  }
}

.view-form {
  padding: 0;
}
