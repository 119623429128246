@import url(./variable.less);

html {
  font-family: 'Primary', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Primary', sans-serif;
  background: @tq-ivory;
  font-size: 14px;
}

@import url(./common/index.less);
@import url(./Header/header.less);
@import url(./Header/footer.less);
@import url(./quill.less);

.container-marketing {
  max-width: 1240px;
  width: 100%;
  margin: auto;
}

.container {
  margin: auto;
  padding: 0 48px;

  @media @screen1150 {
    padding: 0 24px;
  }

  @media @screen767 {
    padding: 0 16px;
  }
}

.left-container {
  margin: auto;
  padding-left: 48px;

  @media @screen1150 {
    padding-left: 24px;
  }

  @media @screen767 {
    padding-left: 16px;
  }
}

.container-rnp {
  margin: auto;
  padding: 0 48px;

  @media @screen1150 {
    padding: 0 24px;
  }

  @media @screen767 {
    padding: 0;
  }
}

.pointer {
  cursor: pointer;
}

.wrapper {
  min-height: calc(100vh - 151px);
  .logout-spinner {
    height: calc(100vh - 151px);
    width: 100%;
    .ant-spin {
      width: 100%;
      display: flex;
      .ant-spin-dot-item {
        background-color: #ffffff;
      }
    }
  }

  @media @screen767 {
    min-height: calc(100vh - 128px);
  }
}

.disabled-link {
  pointer-events: none;
}

// .player-wrapper {
//   width: auto;
//   height: auto;
//   max-height: calc(100vh - 74px);
// }
// .react-player {
//   padding-top: 56.25%;
//   position: relative;
// }
