.donate-page {
  &.inner-page {
    padding: 32px 0 72px 0;

    @media @screen767 {
      padding: 0 0 48px 0;
    }
    &.form-display-wrapper {
      .donate-block {
        margin: 0;
        .donate-form {
          background: var(--tq-neutral-600);
        }
      }
    }
    .donate-block {
      .bg-n-700;
      box-shadow: @tq-shadow-top;
      max-width: 576px;
      margin: auto;
      border-radius: 16px;
      overflow: hidden;
      border: 1px solid var(--tq-border-dark);
      @media @screen767 {
        box-shadow: unset;
        max-width: 100%;
        border-radius: 0;
      }

      .cs-devider {
        max-width: 351px;
        margin: 24px auto;

        @media @screen767 {
          max-width: 240px;
          margin: 16px auto;
        }
      }

      .d-title,
      .d-dec {
        display: none;
      }

      .donate-wrapper {
        padding: 0;

        .amount-wrapper {
          margin: 0;
          .d-flex;
          .flex-vertical;

          .amount-video-section .video-block.video-flex {
            p {
              display: none;
            }

            .video-img {
              max-width: 100%;
              padding-top: 55.834%;
              margin-bottom: 16px;
            }
          }

          .amount-form {
            padding: 33px 48px;
          }

          .amount-video-section.amount-video-section-flex {
            width: 100%;
            flex-direction: column-reverse;
            padding: 40px 48px 32px 48px;
          }

          .amount-form {
            width: 100%;
          }
        }
      }

      .container {
        padding: 0;
      }

      h1 {
        .heading-h1;
        text-align: center;
        margin: 16px 0 32px 0;

        @media @screen767 {
          margin: 0 0 24px 0;
        }
      }

      .donate-amount {
        background: var(--tq-moss-2);
        padding: 24px 16px 16px 16px;
        text-align: center;
        color: var(--ant-color-text);

        @media @screen767 {
          padding: 24px 16px;
          padding-bottom: 18px;
        }

        .d-price {
          @media @screen767 {
            margin-bottom: 6px;
          }
        }
        .title {
          .heading-h4;
          margin-bottom: 20px;
        }
        .ant-radio-group {
          display: flex;
          justify-content: center;
          color: var(--tq-black);
        }
        .ant-radio + span {
          // .text-body-sm;

          @media @screen767 {
            // .text-body-md;
          }
        }

        .ant-form-item {
          margin-bottom: 0;
        }
      }

      .donate-form {
        padding: 32px 48px;
        position: relative;
        min-height: 200px;
        @media @screen767 {
          padding: 24px 16px 24px 16px;
        }
        .ant-radio-group {
          display: flex;
        }
        .form-group {
          margin-bottom: 24px;
          .ant-form-item .ant-form-item-label > label {
            line-height: 1.2;
          }

          &.margin-16 {
            margin-bottom: 16px;
          }

          @media @screen767 {
            margin-bottom: 20px;
          }

          .ant-form-item-extra {
            margin-top: 2px;
          }
        }

        .donate-submit-btn {
          margin-top: 8px;

          @media @screen767 {
            margin-top: 4px;
          }
        }

        .cs-accordion {
          margin-top: 32px;
        }
        .loader {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

.ant-radio-group {
  .ant-radio + span {
    // .text-body-sm;

    @media @screen767 {
      // .text-body-md;
    }
  }
}

.card-detail {
  .d-flex;
  gap: 16px;

  .form-group {
    flex: 1;

    &:last-child {
      max-width: 148px;
      width: 100%;

      @media @screen767 {
        max-width: 116px;
      }
    }
  }
}

.donation-successful {
  padding: 38px 20px;
  background: var(--blue);
  .relative;
  border-radius: 4px;
  text-align: center;

  @media @screen767 {
    padding: 22px;
  }

  &:before {
    content: '';
    height: calc(100% - 12px);
    width: calc(100% - 12px);
    left: 6px;
    top: 6px;
    border: 1px solid var(--blue);
    position: absolute;
  }

  p {
    .text-n-800;
    max-width: 299px;
    margin: auto;
  }

  h4 {
    .heading-h4;
    .mb-16;
  }

  .donation-avatar {
    .d-flex;
    .align-center;
    .justify-center;
    // .heading-h4;
    margin-bottom: 24px;
  }

  .donation-code {
    font-family: 'Primary';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin-top: 16px;

    @media @screen767 {
      font-size: 14px;
      letter-spacing: 0.04em;
    }
  }
}

.donate-form-inner {
  position: relative;
  z-index: 1;
}

.d-price {
  font-style: normal;
  font-weight: 400;
  font-size: 66px;
  line-height: 70px;
  color: var(--black);
  @media @screen767 {
    font-size: 58px;
    line-height: 64px;
  }

  span {
    position: relative;
    font-family: @tq-secondary-font;

    sup {
      font-family: @tq-secondary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      position: absolute;
      left: -15px;
      top: -5px;
    }

    button {
      padding: 0;
      bottom: 0;
      position: absolute;
      right: -34px;
    }
  }
}

.donate-sucessful-btn {
  margin-top: 32px;

  @media @screen767 {
    margin-top: 0;
    padding: 16px 16px 24px 16px;
    .bg-n-700;
    box-shadow: 0px -14px 6px rgba(53, 36, 10, 0.01),
      0px -4px 4px rgba(53, 36, 10, 0.05), 0px -1px 2px rgba(53, 36, 10, 0.05);
    border-radius: 8px 8px 0px 0px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.dthankyou {
  @media @screen767 {
    margin-bottom: 90px;
  }
}

.donate-page {
  &.main-page {
    .donate-wrapper {
      padding: 0;

      .amount-wrapper {
        max-width: 1152px;
        margin-top: 32px;

        @media @screen767 {
          margin-top: 0;
          border-radius: 0;
        }

        .amount-video-section {
          @media @screen1024 {
            padding: 30px 40px;
          }

          @media @screen767 {
            padding: 24px 16px;
          }

          .video-section-title {
            @media @screen767 {
              margin-bottom: 0;
            }
          }

          .video-block .video-img {
            @media @screen767 {
              margin-bottom: 16px;
            }
          }
        }

        .amount-form {
          @media @screen1024 {
            padding: 30px 40px;
          }

          @media @screen767 {
            padding: 24px 16px 48px;
          }
        }
      }
    }
  }
}

.preview-image {
  object-fit: contain;
}
