.ctaBanner-section {
  padding: 80px 0;

  @media @screen1024 {
    padding: 60px 0;
  }

  @media @screen767 {
    padding: 0;
  }

  .container-marketing {
    .cta-bnnner-block.ctabannerbackgroundcolor {
      @media @screen1024 {
        padding: 40px;
        width: calc(100% - 120px);
        margin: auto;
      }

      @media @screen767 {
        width: 100%;
        padding: 60px 16px;
      }
    }
  }

  .cta-bnnner-block {
    width: 100%;
    position: relative;

    .ant-btn {
      @media @screen1024 {
        height: 48px;
      }
    }

    &.ctabannerbackgroundcolor {
      padding: 40px 0;
      border-radius: 16px;

      @media @screen767 {
        padding: 60px 16px;
        border-radius: 0;
      }
    }

    &.ctabannerbackground-none {
      padding: 0;

      @media @screen767 {
        padding: 60px 16px;
      }

      .cta-content {
        .description {
          @media @screen1024 {
            .paragraph-l-xl;
          }
        }
      }
    }

    .cta-content {
      position: relative;
      z-index: 1;
      max-width: 1000px;
      margin: auto;
      .text-center;

      @media @screen1024 {
        max-width: 715px;
        margin: auto;
      }

      .title {
        .display-h2;
        .mb-16;

        @media @screen1024 {
          .display-h3;
        }
      }

      .description {
        .paragraph-l-xl;
        margin-bottom: 40px;

        @media @screen1024 {
          .heading-h5;
        }
      }
    }

    &.ctabannerbackground {
      padding: 80px 16px;
      border-radius: 16px;
      overflow: hidden;

      @media @screen1024 {
        max-width: 634px;
        margin: auto;
      }

      @media @screen767 {
        border-radius: 0;
        padding: 60px 16px;
        width: 100%;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: radial-gradient(
            50% 50% at 50% 50%,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.0504834) 70.17%,
            rgba(0, 0, 0, 0.5) 100%
          ),
          linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
        z-index: 1;
      }
    }
  }
}
