.ctacard {
  .d-flex;
  border-radius: 16px;
  overflow: hidden;

  @media @screen767 {
    .flex-vertical;
    padding: 32px 24px;
    border-radius: 0;
  }

  &.ctaMarketing {
    max-width: 1240px;
    margin: auto;
    width: 100%;
    box-shadow: 0px 19px 8px rgba(78, 68, 54, 0.02),
      0px 5px 7px rgba(78, 68, 54, 0.13);
    border-radius: 24px;

    @media @screen1024 {
      max-width: calc(100% - 48px);
      margin: auto;
    }

    @media @screen767 {
      max-width: 100%;
      box-shadow: unset;
      border-radius: 0;
    }

    .ctacard-dec {
      padding: 40px 32px;

      @media @screen1024 {
        padding: 32px;
      }

      @media @screen767 {
        padding: 24px 0 0 0;
      }

      .date {
        .heading-h4;
        margin-bottom: 4px;

        @media @screen1024 {
          .heading-h5;
        }
      }

      .paragraph {
        .paragraph-m;

        @media @screen1024 {
          .paragraph-s;
          margin-bottom: 24px;
          max-width: 100%;
        }
      }

      .cta-button-wrapper {
        flex-direction: row-reverse;
        max-width: 100%;
        .align-start;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 16px;

        @media @screen1150 {
          gap: 8px;
        }
        @media @screen375 {
          flex-wrap: wrap;
        }

        button {
          width: auto;
          flex: inherit;

          @media @screen1150 {
            flex: 1;
          }
        }
      }
    }
  }

  .ctaCard-img {
    margin-bottom: 0;
    flex: 0 0 48.81%;
    min-height: 344px;
    padding-top: 25.596%;

    @media @screen767 {
      min-height: 0;
      padding-top: 56.27%;
      border-radius: 8px;
      overflow: hidden;
    }
  }

  .ctacard-dec {
    padding: 64px;
    width: 100%;
    .d-flex;
    .flex-vertical;
    .justify-center;

    @media @screen1024 {
      padding: 40px;
    }

    @media @screen767 {
      padding: 24px 0 0 0;
      text-align: center;
    }

    &.only-dec {
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    h3 {
      font: @tq-display-h2;
      letter-spacing: 0.02em;
      .mb-4;
      max-width: 484px;

      @media @screen1024 {
        .display-h3;
      }

      @media @screen767 {
        font: @tq-display-h3;
        max-width: 100%;
      }
    }

    .paragraph {
      font: @tq-paragraph-l;
      max-width: 484px;
      margin-bottom: 32px;

      @media @screen767 {
        .paragraph-m;
        margin-bottom: 24px;
        max-width: 100%;
      }
    }
  }

  .cta-button-wrapper {
    .d-flex;
    justify-content: flex-start;
    margin: 0 -4px;
    width: 100%;

    @media @screen767 {
      margin: auto;
      .justify-center;
      max-width: 100%;
    }
    @media @screen375 {
      flex-wrap: wrap;
      gap: 8px;
    }
    button {
      width: 50%;
      margin: 0 4px;
      flex: 1;
      height: auto;
      white-space: inherit;
    }
  }
}

/*banner component css start here*/
.banner {
  position: relative;
  width: 100%;
  background: #000000;
  height: 366px;
  @media @screen1024 {
  }

  .container {
    position: relative;
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      &.desktop-img {
        display: block;
        @media @screen1024 {
          display: none;
        }
      }
      &.tablet-img {
        display: none;
        @media @screen1024 {
          display: block;
        }
        @media @screen767 {
          display: none;
        }
      }
      &.mobile-img {
        display: none;
        @media @screen767 {
          display: block;
        }
      }
    }
  }
}

/*banner component css end here*/
